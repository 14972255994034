import React, {useEffect, useRef, useState} from "react";

import {useGetOpenOrdersQuery} from "../../store/Api";
import {
    CommandBar,
    DetailsList,
    IColumn,
    ICommandBarItemProps,
    SelectionMode,
} from "@fluentui/react";
import { IOrder } from "../../models/Order";
import {renderOrderColumns} from "../../logic/Columns/OrderColumns";
import OrderStatusModal from "../../components/Procurement/OrderStatusModal";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {copyAndSort} from "../../logic/Columns/ColumnUtil";
import Footer from "../../components/Layout/Footer";

const ActiveOrdersPage: React.FC = () => {
    
    const activeOrders = useGetOpenOrdersQuery();
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [columns, setColumns] = useState<IColumn[]>([])
    const [selected, setSelected] = useState<IOrder>()
    const [myOrdersOnly, setMyOrdersOnly] = useState<boolean>()
    const user = useSelector((state: RootState) => state.auth.userName);
    const refOrders = useRef(orders)
    const refColumns = useRef(columns);
    useEffect(() => {
        if(activeOrders.data){
            const data = myOrdersOnly == true ? activeOrders.data.filter(a => a.username == user) : activeOrders.data
            updateOrders(data)
            updateColumns(renderOrderColumns((order) => setSelected(order), sortColumn))
        }
    }, [activeOrders, myOrdersOnly]);

    const updateOrders = (orders: IOrder[]) => {
        setOrders(orders)
        refOrders.current = orders
    }
    const updateColumns = (columns: IColumn[]) => {
        setColumns(columns)
        refColumns.current = columns
    }
    const commands: ICommandBarItemProps[] = [
        {
            key: 'Scope',
            text: myOrdersOnly ? 'Show All' : 'My Orders Only',
            iconProps: {iconName: myOrdersOnly? 'ClearFilter' : 'Filter' },
            onClick: () => {
                if(myOrdersOnly){
                    setMyOrdersOnly(false)
                }
                else{
                    setMyOrdersOnly(true)
                }
            }
        }
    ]
    
    const sortColumn = (column: IColumn): void => {
        let isSortedDescending = column.isSortedDescending;

        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        const newSortedItems = copyAndSort(refOrders.current, column.fieldName!, isSortedDescending);

        refColumns.current.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });
        
        updateOrders(newSortedItems);
    };
    return (
        <div className="main-content">
            <h1>Active Orders</h1>
            <CommandBar items={commands}/>
            <DetailsList
                items={refOrders.current ?? []}
                columns={refColumns.current}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
            />
            {selected && 
                <OrderStatusModal 
                    order={selected}
                    closer={() => {setSelected(undefined)}}
                />
            }
            <Footer className={'powered-by-footer-absolute'}/>
        </div>
    );
}

export default ActiveOrdersPage;
