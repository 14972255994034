import React, {useEffect, useRef, useState} from "react";

import {useGetClosedOrdersQuery} from "../../store/Api";
import {IColumn, SelectionMode, ShimmeredDetailsList} from "@fluentui/react";
import { IOrder } from "../../models/Order";
import {renderOrderColumns} from "../../logic/Columns/OrderColumns";
import OrderStatusModal from "../../components/Procurement/OrderStatusModal";
import { copyAndSort } from "../../logic/Columns/ColumnUtil";
import Footer from "../../components/Layout/Footer";

const CompletedOrdersPage: React.FC = () => {
    
    const closedOrders = useGetClosedOrdersQuery();
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [columns, setColumns] = useState<IColumn[]>([])
    const [selected, setSelected] = useState<IOrder>()
    const refOrders = useRef(orders)
    const refColumns = useRef(columns);
    useEffect(() => {
        if(closedOrders.data){
            updateOrders(closedOrders.data)
            updateColumns(renderOrderColumns((order) => setSelected(order), sortColumn))
        }
    }, [closedOrders]);

    const updateOrders = (orders: IOrder[]) => {
        refOrders.current = orders
        setOrders(orders)
    }
    const updateColumns = (columns: IColumn[]) => {
        refColumns.current = columns
        setColumns(columns)
    }
    const sortColumn = (column: IColumn): void => {
        let isSortedDescending = column.isSortedDescending;

        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        const newSortedItems = copyAndSort(refOrders.current, column.fieldName!, isSortedDescending);

        refColumns.current.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });

        updateOrders(newSortedItems);
    };
    return (
        <div className="main-content">
            <h1>Completed Orders</h1>

            <ShimmeredDetailsList
                items={refOrders.current ?? []}
                columns={refColumns.current}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
            />
            {selected && 
                <OrderStatusModal 
                    order={selected}
                    closer={() => {setSelected(undefined)}}
                />
            }
            <Footer className={'powered-by-footer-absolute'}/>
        </div>
    );
}

export default CompletedOrdersPage;
