import React, {useState} from "react";
import {IUploadFileResponse} from "../../dal/BaseDAL";
import {useUploadRecommendationFileMutation, useUploadStorefrontFileMutation} from "../../store/Api";
import {
    IsFetchBaseQueryError,
    IsMutationError,
    IsMutationSuccess,
    IsSerializedError
} from "../../logic/MutationTypeChecker";
import { Label, MessageBar, MessageBarType, PrimaryButton, Stack, StackItem, TextField } from "@fluentui/react";
import { ArrayBufferToBase64 } from "../../logic/ArrayBufferConverter";


export enum FileUploadType {
    Extension,
    StorefrontCSS
}

interface IFileUploadProps {
    label?: string;
    fileUploadType?: FileUploadType;
    existingFile?: string;
    extensionId?: string
    onSuccess: (url: string) => void;
}

const FileUpload: React.FC<IFileUploadProps> = (props) => {
    const [uploadExtensionFile] = useUploadRecommendationFileMutation();
    const [uploadStorefrontFile] = useUploadStorefrontFileMutation();
    const [uploadingFile, setUploadingFile] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>();
    const [fileName, setFileName] = useState<string | undefined>();

    React.useEffect(() => {
        const parts = props.existingFile?.split('/');
        if (parts) {
            setFileName(parts[parts.length - 1]);
        } else {
            setFileName(undefined);
        }
    }, [props.existingFile]);
    
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const handleExtensionFile = async (file: ArrayBuffer, fileName: string) => {
        if (props.extensionId == null) {
            setError('No extension id provided');
            return;
        }
        const result = await uploadExtensionFile({extension: props.extensionId, file, fileName: fileName ?? 'document'});
        if (IsMutationSuccess<IUploadFileResponse>(result)) {
            props.onSuccess(result.data.url);
        } else if (IsMutationError(result)) {
            if (IsFetchBaseQueryError(result.error)) {
                setError(`Error uploading file: ${result.error.status}`)
            } else if (IsSerializedError(result.error)) {
                setError(`Serializing error uploading file: ${result.error.name}`)
            }
        }
    }

    const handleStorefrontFile = async (file: ArrayBuffer, fileName: string) => {
        setUploadingFile(true);
        setError(undefined);
        const result = await uploadStorefrontFile({file: ArrayBufferToBase64(file), fileName: fileName ?? 'stylesheet', type: 'CSS'});
        if (IsMutationSuccess<IUploadFileResponse>(result)) {
            props.onSuccess(result.data.url);
            console.log(['UPLOAD SUCCESS', result.data.url]);
        } else if (IsMutationError(result)) {
            if (IsFetchBaseQueryError(result.error)) {
                setError(`Error uploading file: ${result.error.status}`)
            } else if (IsSerializedError(result.error)) {
                setError(`Serializing error uploading file: ${result.error.name}`)
            }
        }
        setUploadingFile(false);
    }

    const handleFile = async (file: string | ArrayBuffer | null, fileName: string) => {
        if (file && typeof file !== "string") {
            setUploadingFile(true);
            setError(undefined);
            try {
                switch (props.fileUploadType) {
                    case FileUploadType.Extension:
                        await handleExtensionFile(file, fileName);
                        break;
                    case FileUploadType.StorefrontCSS:
                        await handleStorefrontFile(file, fileName);
                        break;
                }
            } catch (e) {
                setError('Error uploading file');
            }
            setUploadingFile(false);
        }
    }

    const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const file = e.target.files?.[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onload = async () => {
            const f = reader.result;
            console.log(['UPLOADING', f]);
            if (!f || typeof f === 'string') return;
            if (file.size > 20971520) {
                setError("File size exceeds 20 MB");
                return;
            }
            console.log(file);
            handleFile(f, file.name);
        };
        reader.readAsArrayBuffer(file);
    }

    const fileTypes = () => {
        switch (props.fileUploadType) {
            case FileUploadType.Extension:
                return '.gif,.png,.jpg.jpeg,.webp';
            case FileUploadType.StorefrontCSS:
                return '.css';
        }
    }

    const clearFile = () => {
        props.onSuccess('');
        setError(undefined);
    }

    return (
        <div
        style={{marginBottom: '3em'}}
        >
            <input 
                type="file" 
                ref={inputRef} 
                style={{ display: 'none' }} 
                onChange={onChangeFile} 
                accept={fileTypes()}
            />
            <Label>{props.label ?? 'Upload File'}</Label>
            <Stack horizontal tokens={{childrenGap: '0.5em'}} grow>
                <StackItem grow>
                    <TextField
                        value={uploadingFile ? 'Uploading' : (fileName ?? 'No File Selected')}
                        readOnly
                        disabled={uploadingFile}
                    />
                </StackItem>
                <StackItem>
                    {props.existingFile == null && <PrimaryButton onClick={() => inputRef?.current?.click()} >Upload</PrimaryButton>}
                    {props.existingFile != null && <PrimaryButton onClick={clearFile} >Clear </PrimaryButton>}
                </StackItem>
            </Stack>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
        </div>
    );
}

export default FileUpload;