import React from "react";
import ResultTable from "../ResultTable/ResultTable";
import { RootState } from "../../store/rootReducer";
import { useSelector } from "react-redux";
import NoResultsDisplay from "./NoResultsDisplay";
import {useGetSearchResultsQuery} from "../../store/Api";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import { IFilledFilters, ISearchRequest } from "../../models/Search";
import {criteriaIsBlank} from "../../logic/Search";

export enum DisplayFormat {
    table,
    cards,
}

export interface IAlternativeCatalog {
    name: string;
    description: string;
    count: number;
}

interface IResultsDisplayProps {
    runningRealtime?: boolean;
    refreshingVendors?: string[];
    onDetailsSelection: (p: INormalizedProduct) => void;
    onFilterChange: (filters: IFilledFilters) => void;
}


const ResultsDisplay: React.FC<IResultsDisplayProps> = (props) => {
    const catalog = useSelector((state: RootState) => state.search.selectedCatalog);
    const criteria = useSelector((state: RootState) => state.search.criteria);
    const products = useGetSearchResultsQuery({catalog: catalog?.name, criteria});

    if (products.data?.total === 0) {
        const newCriteria : ISearchRequest = {
            keywords: criteria.keywords,
            inStock: criteria.inStock,
            manufacturer: [],
            vendor: [],
            must: [],
            boost: [],
            exclude: [],
            filters: [],
        }
        
        if(criteriaIsBlank(criteria))
            return <NoResultsDisplay message={"Begin a New Search"} criteria={newCriteria} onFilterChange={props.onFilterChange}/>;
        
        else 
            return <NoResultsDisplay criteria={newCriteria} onFilterChange={props.onFilterChange}/>;
    }

    if (!products.isFetching && (products.data == null || products.data.products.length === 0)) {
        return <NoResultsDisplay criteria={criteria} message="Search for products" onFilterChange={props.onFilterChange} />
    }

    return <ResultTable
        onDetailsSelection={props.onDetailsSelection}
        runningRealtime={props.runningRealtime}
        refreshingVendors={props.refreshingVendors}
    />
};

export default ResultsDisplay;
