import React, {useEffect} from "react";
import WorkspaceCard from "../components/Cards/WorkspaceCard";
import FavoritesCard from "../components/Cards/FavoritesCard";
import NotificationsCard from "../components/Cards/NotificationsCard";
import {
    setTargetExtension,
    setMappedExtensionFields,
    setRefreshSource,
    clearSettings,
    updateLoggingOut
} from "../store/settingsSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/rootReducer";
import api, {
    useClearExtensionsMutation,
    useClearSearchMutation,
    useClearWorkspaceCacheMutation,
    useSetSelectedOrganizationMutation
} from "../store/Api";
import {useMsal} from "@azure/msal-react";
import NotificationModal from "../components/Notification/NotificationModal";
import Footer from "../components/Layout/Footer";

export const Home: React.FC = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const org = useSelector((state: RootState) => state.settings.selectedOrganization);
    const dismissedNotification = useSelector((state: RootState) => state.settings.dismissedNotification);
    const [clearWorkspaces] = useClearWorkspaceCacheMutation();
    const [clearSearch] = useClearSearchMutation();
    const [setOrganization] = useSetSelectedOrganizationMutation();
    const [clearExtensions] = useClearExtensionsMutation();
    const {instance} = useMsal(); 
    const dispatch = useDispatch();
    const query = new URLSearchParams(location.search);
    const targetExtension = query.get('source');
    const refreshParentOnExtensionSubmit = query.get('refreshSource')
    const targetExtensionFields = query.get('mappedFields')
    const externalUsername = query.get('username')
    const checkOrg = query.get('acOrg')
    

    useEffect(() => {
        console.log("Evaluating params...", targetExtension, targetExtensionFields, externalUsername)
        if (targetExtension && targetExtensionFields) {
            try {
                const mappedFields: { [key: string]: string; } = JSON.parse(targetExtensionFields)
                dispatch(setTargetExtension(targetExtension));
                dispatch(setMappedExtensionFields(mappedFields));
                console.log('mapped fields:', targetExtensionFields)
                console.log(`Extension: ${targetExtension}`);
            } catch {
                console.log('Could not map fields.')
            }
        }
    }, [targetExtension, targetExtensionFields]);

    useEffect(() => {
        if (externalUsername) {
            console.log('CHECKING USERNAME AND ORG MATCH');
            if (auth.user?.email && auth.user.email.toLowerCase() != externalUsername.toLowerCase() ||
                (checkOrg && org?.id && checkOrg != org?.id)) {
                console.log("Externally provided user doesn't match current session. Logging out.")
                dispatch(updateLoggingOut(true));
                clearSearch();
                dispatch(clearSettings());
                clearWorkspaces();
                clearExtensions();
                setOrganization(undefined);
                dispatch(api.util.resetApiState());
                instance.logoutRedirect()
            }
        }
    }, [instance, externalUsername]);

    useEffect(() => {
        if (refreshParentOnExtensionSubmit) {
            dispatch(setRefreshSource(true))
        }
    }, [refreshParentOnExtensionSubmit]);
    
    return (<div className="main-content">
        {window.config.overrideLogo == null ? <h1>Adaptive Catalog</h1> : <></>}
        {!dismissedNotification ? <NotificationModal/> : null}
        <WorkspaceCard/>
        <FavoritesCard/>
        <NotificationsCard/>
        <Footer/>
    </div>);
}

export default Home;
