import React, {useEffect, useState} from 'react';
import {IGlobalStorefrontSettings, IStorefrontSettings, PAYMENT_PROCESSOR_KEYS} from '../../models/Storefront';
import {Checkbox, IDropdownOption, Label, TextField} from '@fluentui/react';
import CompactColorPicker from '../Inputs/CompactColorPicker';
import FontPicker from '../Inputs/FontPicker';
import NumberField from '../Inputs/NumberField';
import MarkupTypeSelection from './MarkupTypeSelection';
import CurrencySelection from '../CurrencySelection/CurrencySelection';
import StorefrontThemeSelection from './StorefrontThemeSelection';
import PaymentProcessorDropdown from './PaymentProcessorDropdown';
import StorefrontOpportunityExtensionForm from "./StorefrontOpportunityExtensionForm";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {useGetOrganizationsQuery, useGetVendorsQuery} from "../../store/Api";
import StorefrontOrderSettings from "./StorefrontOrderSettings";
import ImageUploader from '../Inputs/ImageUploader';

interface IStorefrontSettingsFormProps {
    globalSettings?: IGlobalStorefrontSettings
    settings: IStorefrontSettings;
    onChange: (settings: IStorefrontSettings) => void;
    onLoading?: (isLoading: boolean) => void;
}

const StorefrontSettingsForm: React.FC<IStorefrontSettingsFormProps> = (props) => {

    const [opportunityExtension, setOpportunityExtension] = React.useState(props.globalSettings?.opportunityExtension);
    const [storefrontVendors, setStorefrontVendors] = React.useState<string[]>(props.settings.storefrontVendors ?? []);
    const [defaultCatalog, setDefaultCatalog] = useState<string>();
    const [subUrlErrorMessage, setSubUrlErrorMessage] = useState<string | undefined>();
    
    const vendors = useGetVendorsQuery(defaultCatalog ?? "", {skip: !defaultCatalog})
    const organizations = useGetOrganizationsQuery();
    
    useEffect(() => {
        if(organizations.data) {
            const defaultOrganization = organizations.data.find(o => o.defaultCatalog);
            if(defaultOrganization) {
                setDefaultCatalog(defaultOrganization.defaultCatalog);
            }
        }
    },[organizations.data]);
    
    React.useEffect(() => {
        setOpportunityExtension(props.globalSettings?.opportunityExtension)
    }, [props.globalSettings?.opportunityExtension]);
    
    React.useEffect(() => {
        setStorefrontVendors(props.settings.storefrontVendors ?? [])
    }, [props.settings.storefrontVendors])
    
    const onChangeVendors = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            props.onChange({...props.settings, 
                storefrontVendors: item.selected ? [...storefrontVendors, item.key as string] : storefrontVendors?.filter(key => key !== item.key) });
        }
    };
    
    const filterStrategyOptions = () => {
        return [
            {key: 'exclude', text: 'Exclude'},
            {key: 'include', text: 'Include'},
        ]
    }
    
    return (
        <div style={{paddingTop: '1em'}}>
            <h3>Settings</h3>
            <Checkbox label='Enabled' checked={props.settings.enabled} onChange={(e, c) => props.onChange({...props.settings, enabled: c})} />
            <TextField label='Sub URL' value={props.settings.subUrl} errorMessage={subUrlErrorMessage} onChange={(e, v) => props.onChange({...props.settings, subUrl: v ?? ''})} />
            <TextField label='Name' value={props.settings.name} onChange={(e, v) => props.onChange({...props.settings, name: v ?? ''})} />
            <TextField label='Title' value={props.settings.title} onChange={(e, v) => props.onChange({...props.settings, title: v ?? ''})} />
            <TextField label='Banner Text' value={props.settings.bannerText} onChange={(e, v) => props.onChange({...props.settings, bannerText: v ?? ''})} />
            <Label>Storefront Banner Image</Label>
            <ImageUploader
                value={props.settings.storefrontBannerImage}
                onChange={(v) => props.onChange({...props.settings, storefrontBannerImage: v})}
                height={50}
                width={200}
                imageId={props.settings.id}
                onClear={() => props.onChange({...props.settings, storefrontBannerImage: ''})}
                imageType='Storefront'
                />
            <TextField label='Storefront Banner Link' value={props.settings.storefrontBannerLink} onChange={(e, v) => props.onChange({...props.settings, storefrontBannerLink: v ?? ''})} />
            <TextField label='Storefront Banner Alt Text' value={props.settings.storefrontBannerAltText} onChange={(e, v) => props.onChange({...props.settings, storefrontBannerAltText: v ?? ''})} />
            <h3>Styles</h3>
            <StorefrontThemeSelection theme={props.settings.theme} onChange={(v) => props.onChange({...props.settings, theme: v ?? ''})} />
            {props.settings.theme === 'CUSTOM' && <>
                <CompactColorPicker label='Menu Bar Color' color={props.settings.menuBarColor ?? '#fff'} onChange={(v) => props.onChange({...props.settings, menuBarColor: v ?? ''})} placeholder={props.globalSettings?.menuBarColor} />
                <CompactColorPicker label='Menu Bar Text Color' color={props.settings.menuBarTextColor ?? '#000'} onChange={(v) => props.onChange({...props.settings, menuBarTextColor: v ?? ''})} placeholder={props.globalSettings?.menuBarTextColor} />
                <CompactColorPicker label='Background Color' color={props.settings.backgroundColor ?? '#fff'} onChange={(v) => props.onChange({...props.settings, backgroundColor: v ?? ''})} placeholder={props.globalSettings?.backgroundColor} />
                <CompactColorPicker label='Text Color' color={props.settings.textColor ?? '#000'} onChange={(v) => props.onChange({...props.settings, textColor: v ?? ''})} placeholder={props.globalSettings?.textColor} />
            </>}
            <FontPicker label='Header Font' selectedFont={props.settings.headerFont} onChange={(v) => props.onChange({...props.settings, headerFont: v ?? ''})} placeholder={props.globalSettings?.headerFont} />
            <FontPicker label='Default Font' selectedFont={props.settings.defaultFont} onChange={(v) => props.onChange({...props.settings, defaultFont: v ?? ''})} placeholder={props.globalSettings?.defaultFont} />
            <StorefrontOrderSettings globalSettings={props.globalSettings} settings={props.settings} onChange={props.onChange}/>
            <h3>Pricing Settings</h3>
            <NumberField label='Default Markup' value={props.settings.defaultMarkup} onChange={(v) => props.onChange({...props.settings, defaultMarkup: v ?? 0})} placeholder={props.globalSettings?.defaultMarkup?.toString()} />
            <MarkupTypeSelection value={props.settings.defaultMarkupType} onChange={(v) => props.onChange({...props.settings, defaultMarkupType: v ?? ''})} label='Markup Type' />
            <h3>Payment Settings</h3>
            <CurrencySelection value={props.settings.currency} onChange={(v) => props.onChange({...props.settings, currency: v ?? ''})} allowInherit />
            <PaymentProcessorDropdown label='Payment Processor' value={props.settings.paymentProcessor ?? PAYMENT_PROCESSOR_KEYS.NONE} onChange={(v) => props.onChange({...props.settings, paymentProcessor: v ?? ''})} />
            <Dropdown
                placeholder={"Select Vendors"}
                multiSelect={true}
                label={"Storefront Vendor Filter"}
                selectedKeys={props.settings.storefrontVendors}
                options={vendors?.data?.items?.map(vendor => ({ key: vendor, text: vendor })) ?? []}
                onChange={onChangeVendors}
                disabled={vendors.isLoading || vendors.isFetching}
            />
            <Dropdown
                placeholder={"Select Filter Strategy"}
                multiSelect={false}
                label={"Vendor Filter Strategy"}
                selectedKey={props.settings.vendorFilterStrategy ?? "include"}
                options={filterStrategyOptions()}
                onChange={(e, v) => {props.onChange({...props.settings, vendorFilterStrategy: v?.key.toString()})}}
            />
            {opportunityExtension && <>
                <StorefrontOpportunityExtensionForm
                    opportunityCreationEnabled={props.globalSettings?.enableOpportunityCreation}
                    subExtensionFolioCompositeId={opportunityExtension}
                    settings={props.settings}
                    onChange={props.onChange}
                    onLoading={props.onLoading}
                />
            </>
            }
        </div>
    );
}

export default StorefrontSettingsForm;