import {INormalizedProduct} from "./NormalizedProduct";
import {IDictionary} from "./ICollectionResponse";
import {IWorkspaceItem} from "./Workspace";
import {IProcurementItem, IPurchaseRequirement} from "./Order";

export interface IExtension {
    id: string;
    category: string;
    name: string;
    description: string;
    ready: boolean;
    statusMessage: string;
    image?: string;
    user?: string;
    additionalData: IAdditionalData[];
    configUrl: string;
    hasSampleLoad: boolean;
    hasValidation: boolean;
    mapToVendor?: boolean;
    defaultVendor?: string;
}

export interface IExtensionFolioSubextensionReference {
    identifier?: string;
    sourceFolio?: string;
    name?: string;
}

export interface IExtensionFieldInstallOptions {
    field: string;
    options: string[];
}

export enum FrequencyOption {
    Daily = "daily",
    Weekly = "weekly",
    Monthly = "monthly",
    Once = "once",
}
export enum TimeWindow {
    TwelveAMSixAM = "12 AM - 6 AM",
    SixAMTwelvePM = "6 AM - 12 PM",
    TwelvePMSixPM = "12 PM - 6 PM",
    SixPMTwelveAM = "6 PM - 12 AM",
}
export enum DayOfWeek {
    Sunday = "sunday",
    Monday = "monday",
    Tuesday = "tuesday",
    Wednesday = "wednesday",
    Thursday = "thursday",
    Friday = "friday",
    Saturday = "saturday",
}

export interface IImportFrequency {
    frequency: FrequencyOption;
    time: TimeWindow;
    dayOfWeek: DayOfWeek[];
    dayOfMonth: number[];
}

export interface IInstalledExtension {
    id: string;
    name: string;
    source: string;
    enabled: boolean;
    extensionType: string;
    vendor: string;
    additionalData: IAdditionalData[];
    runOrder?: number;
    nickname: string;
    allowAdHocItems?: boolean;
    useLinkedItems?: boolean;
    link?: string;
    linkName?: string;
    linkedItemType?: string;
    allowPrice?: boolean;
    schedule?: string;
    mappings?: IImportMapping[];
    allowFileLibrary?: boolean;
    steps?: IExtensionStep[];
    subOrganization?: string;
    user?: string;
    targetCatalog?: string;
    staticData?: {[key:string]: string};
    displayMode?: string;
    selectionSource?: string;
    hasValidation?: boolean;
    isSubExtension?: boolean;
    createProducts?: boolean;
}

export interface IAdditionalData {
    maxValue?: number;
    topic?: string;
    isFilterSearch?: boolean;
    field: string;
    description: string;
    fieldType: string;
    defaultValue: string;
    protected: boolean;
    dataType: string;
    hasOptions: boolean;
    adHocOnlyField?: boolean;
    searchable?: boolean;
    optionsFields?: { type: string, field: string }[]
    optional: boolean;
    fieldFilter?: IAdditionalDataFieldFilter;
    step?: string;
    parentField?: string;
    supportsToken?: boolean;
    mask?: string;
    maskChar?: string;
    maskFormat?: string;
    friendlyNameUrl?: string;
}

export interface IExtensionStep {
    order: number;
    name: string;
    requiredFields?: string[]
    identifier: string;
    flag?: string;
    showItems?: boolean
}

export interface IAdditionalDataFieldFilter {
    filterType: string;
    target: string;
    value: string;
}

export interface IProcurementRealTimePriceQuantityUpdateResponse {
    error?: string;
    success?: boolean;
    warning?: string;
    items?: IProcurementItem[];
}

export interface IMultipleRealTimeExtensionResponse {
    success: boolean;
    error: string;
    items: IMultipleRealTimeExtensionResponseItem[];
}

export interface IMultipleRealTimeExtensionResponseItem {
    id: string;
    vendors: IMultipleRealTimeExtensionResponseVendor[];
}

export interface IMultipleRealTimeExtensionResponseVendor {
    vendorName: string;
    vendorPartNumber: string;
    active: boolean;
    onHand?: number;
    manufacturerPartNumber?: string;
    description?: string;
    cost?: number;
    error?: string;
    warehouses?: IRealTimeExtensionResponseItemWarehouse[];
    inStockDate?: Date;
}

export interface IRealTimeExtensionResponse {
    success: boolean;
    error: string;
    items: IRealTimeExtensionResponseItem[];
}

export interface IRealTimeExtensionResponseItem {
    vendorPartNumber: string;
    active: boolean;
    onHand?: number;
    manufacturerPartNumber?: string;
    description?: string;
    cost?: number;
    inStock?: boolean;
    error?: string;
    warehouses?: IRealTimeExtensionResponseItemWarehouse[];
    inStockDate?: Date;
    newMatch?: boolean;
    priceBreaks?: IRealTimeExtensionResponseItemPriceBreak[];
    quantityCost?: number;
    referenceId?: string;
}

export interface IRealTimeExtensionResponseItemPriceBreak {
    quantity: number;
    price: number;
    selected?: boolean;
}

export interface IRealTimeExtensionResponseItemWarehouse {
    warehouse: string;
    onHand: number;
    inStockDate?: Date;
}

export interface IDirectImportExtensionResponse extends IExtensionResponse {
    items?: IWorkspaceItem[]
}

export interface IDirectImportExtensionPreviewResponse extends IExtensionResponse{
    totalItemCount?: number;
    sortableFields?: string[];
    filterableFields?: string[];
    items?: IDirectImportItem[]
}
export interface IDirectImportExtensionPreviewRequest {
    objectId?: string;
    sourceName: string;
    page?: number;
    sortBy?: string;
    filterField?: string;
    filterValue?: string;
    sortByDescending?: boolean;
    pageSize?: number;
}

export interface IDirectImportExtensionRequest {
    items: IDirectImportItem[]
    catalog: string;
    workspaceId: string;
}

export interface IDirectImportItem {
    manufacturerPartNumber?: string;
    cost?: number;
    manufacturer?: string;
    name?: string;
    quantity?: number;
    vendorPartNumber?: string;
    active?: boolean;
    inStock?: boolean;
    onHand?: number;
    description?: string;
    source?: string;
    acProduct?: string;
    opportunity?: string;
    salesOrder?: string;
    price?: number;
    location?: string;
    company?: string;
    orderQuantity?: number;
    productClass?: string;
    purchaseDate?: string
    vendor?: string;
    margin?: number;
    businessUnit?: string;
    status?: string;
    [Key: string]: string | boolean | number | Date | undefined;
}

export interface ICustomOption {
    [Key: string]: string | boolean | number | undefined;
}

export interface IExtensionResponse {
    success: boolean;
    redirectUrl?: string;
    error: string;
    message?: string;
    messageHeader?: string;
    products?: string[];
}

export interface IRecommendationResponse extends IExtensionResponse {
    matchedProducts: IRecommendationMatchedProduct[];
    possibleProducts: IRecommendationMatchedProduct[];
}

export interface IRecommendationMatchedProduct {
    productId?: string;
    score: number;
    scorer: string;
    source: string;
    matchGroup: string;
    matchedTo?: string;
    quantity?: number;
    manufacturerPartNumber?: string;
    name?: string;
    product?: INormalizedProduct;
}

export interface IProductReference {
    catalog: string;
    id: string;
    quantity?: number;
}

export interface IExtensionField {
    fieldType: "text" | "password" | "number";
    fieldName: string;
    options?: string[] | number[];
    required: boolean;
    optionApi?: string;
    description: string;
}

export interface IExtensionConfig {
    version: number;
    fields: IExtensionField[];
    postApi: string;
}

export interface IExtensionInstallRequest {
    additionalData: {[key:string]:string};
    extensionLevel?: string;
    extensionNickname: string;
    vendor?: string;
    catalog?: string;
    mappings?: IImportMapping[];
    schedule?: string;
    existingInstalledId?: string;
    extensionType?: string;
    validationActions?: string[];
}

export interface IFolioValidationRequest{
    additionalData: {[key:string]:string};
    validationActions?: string[];
    folioId?: string;
}

export interface IExtensionOptionsResponse {
    success: boolean;
    error?: string;
    options: IExtensionOption[];
}

export interface IExtensionOption {
    value: string
    display: string,
    default?: boolean,
    additionalProperties?: IDictionary<string | number | boolean>
}

export interface IExtensionLink {
    type: string;
    acId: string;
    acCatalog: string;
    externalId: string;
    link: string;
}

export interface IGetExtensionLinksRequest {
    products: {
        acId: string;
        acCatalog: string;
    }[];
}
export interface IGetExtensionLinksResponse {
    links: IExtensionLink[];
}

export interface ISavedExtensionField {
    extensionId: string;
    key: string;
    value: string;
}

export interface ISavedExtensionFieldValue {
    value: string;
}

export interface IGetExtensionFieldsResponse {
    fields: ISavedExtensionField[];
}
export interface IProcurementOrderRequirementsResponse {
    requirementOptions?: IPurchaseRequirement[]
}

export interface IGetExtensionLinksRequest {
    products: {
        acId: string;
        acCatalog: string;
    }[];
}
export interface IGetExtensionLinksResponse {
    links: IExtensionLink[];
}
export interface IFreightOptionResponse {
    error?: string;
    success?: boolean;
    freightOptions?: IFreightOption[]
}
export interface IFreightOption {
    carrierIdentifier?: string;
    carrierName?: string;
    estimatedDays?: number;
    freightAmount?: number;
    shipFromLocation?: string;
}
export interface IProcurementCsvGenerationRequest{
    procurementItemIds?: string[];
}

export interface IExtensionPotentialMatchResponse {
    success: boolean;
    error?: string;
    products: IExportPotentialMatch[];
}

export interface IExportPotentialMatch {
    acId: string;
    id: string;
    mfp: string;
    name: string;
}

export interface IImportExtensionMappingSampleRequest {
    installedExtensionId?: string;
    additionalFields: {[key:string]: string};
}

export interface IImportExtensionMappingSampleResponse {
    columns: string[][];
    success: boolean;
    error: string;
}

export interface IImportMapping {
    column: number;
    field: string;
    attribute?: string;
}

export interface IExtensionLog {
    extensionId: string;
    extensionNickname: string;
    success: boolean;
    message?: string;
    timestamp: Date;
    action?: string;
    timeTake?: number;
    username?: string;
    errorMessage?: string;
    productCount?: number;
    productsCreated?: number;
    productsSkipped?: number;
    productsUpdated?: number;
    recommendedProductCount?: number;
    potentialMatchProductCount?: number
}

export interface IExtensionFile {
    user?: string;
    name: string;
    notes?: string;
    version?: number;
    enabled: boolean;
    extensionId: string;
    id: string;
}

export interface IUnmatchedExtensionProduct {
    id: string;
    extensionId: string;
    sourceType: string;
    source: string;
    sourceId: string;
    created: Date;
    name?: string;
    manufacturerPartNumber?: string;
    manufacturer?: string;
    status?: string;
    category?: string;
    subCategory?: string;
    msrp?: number;
    upc?: string;
    cost?: number;
    onHand?: number;
    inStock?: boolean;
    active?: boolean;
    description?: string;
    confirmedMatch?: string;
    confirmedCreateNew?: boolean;
    possibleMatches?: IUnmatchedExtensionProductPossibleMatch[];
}

export interface IUnmatchedExtensionProductPossibleMatch {
    acId: string;
    name?: string;
    manufacturerPartNumber?: string;
    manufacturer?: string;
}

export interface IInstalledExtensionFolio {
    schedule?: string;
    vendor?: string;
    targetCatalog?: string;
    id?: string;
    name?: string;
    nickname?: string;
    sourceExtensionFolio?: IExtensionFolio;
    subExtensionInstalledFields?: { [key: string]: { [key: string]: string } };
    commonInstalledFields?: {[key: string]: string }
    subExtensionHashes?: {[key: string]: string }
    enabled?: boolean;
    enabledSubExtensions?: string[];
    hasValidation?: boolean
    extensionLevel?: string;
    createProducts?: boolean;
}

export interface ISubExtension {
    identifier: string;
    category: string;
    name: string;
    description?: string;
    cron?: string;
    displayMode?: string;
    allowFileLibrary: boolean;
    steps?: IExtensionStep[];
    url: string;
    installCallUrl?: string;
    sampleUrl?: string;
    uninstallCallUrl?: string;
    taskStatusUrl?: string;
    additionalData?: IAdditionalData[];
    mapToVendor?: boolean;
    validateName?: string;
    enabled?: boolean;
    extensionType?: string;

}


export interface IExtensionFolio {
    id?: string;
    subExtensions?: { [key: string]: ISubExtension };
    sharedFields?: IAdditionalData[];
    encryption?: string;
    displayMode?: string;
    defaultVendor?: string;
    supportsSubOrganization: boolean;
    supportsQuantityPriceBreaks?: boolean;
    validateUrl?: string;
    image?: string;
    user?: string;
    name?: string;
    description?: string;}

export const EXTENSION_TYPE_REALTIME = 'realtime';
export const EXTENSION_TYPE_IMPORT = 'import';
export const EXTENSION_TYPE_EXPORT = 'export';
export const EXTENSION_TYPE_WEBHOOK = 'webhook';
export const EXTENSION_TYPE_PROCUREMENT = "procurement";
export const EXTENSION_TYPE_DIRECTIMPORT = 'directImport';
export const EXTENSION_TYPE_CUSTOMERIMPORT = 'customerImport';