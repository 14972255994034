import React from "react";
import './LoginCard.css'

interface IAuthenticating{
  message?: string
}
const Authenticating: React.FC<IAuthenticating> = (props) => {
  return <div>
    <div id="LoginContainer">
      <div id="LoginCard">
        <div style={{textAlign: 'center'}}>
          {window.config.overrideLogo != null ?
              <img src={window.config.overrideLogo} width={250} alt={"White Label Logo"}/> :
              <img src={"mainLogo.png"} width={250} alt={"Adaptive Catalog Logo"}/>}
        </div>
        <div style={{textAlign:"center"}}>{props.message ?? 'Logging In'}</div>
      </div>
    </div>
  </div>;
};

export default Authenticating;
