import React from 'react'
import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardPreview,
    DocumentCardStatus,
    DocumentCardTitle,
    DocumentCardType,
    getTheme,
    mergeStyleSets,
    Stack
} from "@fluentui/react";
import {useHistory} from "react-router-dom";
import {
    useGetApiKeysQuery,
    useGetCatalogsQuery,
    useGetInstalledExtensionsQuery,
    useGetSubOrganizationsQuery, useGetTaxCodesQuery
} from "../../store/Api";
import {RootState} from "../../store/rootReducer";
import {useSelector} from "react-redux";
import CAN, {
    CATALOG_MODIFY,
    ORG_EXTENSION_MODIFY,
    ORG_MODIFY,
    STOREFRONT_ADMIN,
    TAX_CODE_MODIFY, USER_EXTENSION
} from "../../permissions/ability";
import Footer from "../../components/Layout/Footer";

const classes = mergeStyleSets({
    cell: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '1em',
        float: 'left',
        height: '10em',
        width: '10em',
        padding: '2em',
        textAlign: 'center',
        border: '1px solid #ccc',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    icon: {
        fontSize: '50px',
        marginTop: '0.5em',
    },
    header: {
        marginTop: '1.5em',
        marginBottom: '0.5em',
        fontSize: '1.4em',
    },
    secondaryHeader: {
        '@media(max-width: 600px)': {
            display: 'none',
        }
    }
});

const SettingsHomePage: React.FC = () => {
    const organization = useSelector((state: RootState) => state.settings.selectedOrganization);
    const catalogs = useGetCatalogsQuery();
    const subOrganizations = useGetSubOrganizationsQuery();
    const extensions = useGetInstalledExtensionsQuery();
    const apiKeys = useGetApiKeysQuery();
    const taxCodes = useGetTaxCodesQuery();
    const canOrgModify = CAN(ORG_MODIFY);
    const canCatalogModify = CAN(CATALOG_MODIFY);
    const canTaxCodeModify = CAN(TAX_CODE_MODIFY);
    const canOrgExtensionModify = CAN(ORG_EXTENSION_MODIFY);
    const canUserExtension = CAN(USER_EXTENSION);
    const canStorefrontAdmin = CAN(STOREFRONT_ADMIN);

    const reportingEnabled = organization?.apiUsageBillingEnabled;


    return (
        <div style={{padding: '1em'}}>
            <h1>Settings</h1>
            <h2>Organization Settings</h2>
            <Stack tokens={{childrenGap: '1em'}} horizontal wrap>
                {canOrgModify && <SettingsNavDoc
                    icon='CityNext2'
                    header='Organization'
                    route={'/settings/organization'}
                    secondaryHeader='Update organization level settings'
                    compact
                />}
                <SettingsNavDoc
                    icon='Search'
                    header='Search Settings'
                    route={'/settings/search'}
                    secondaryHeader='Update user & global search settings'
                    compact
                />
                {canOrgModify && <SettingsNavDoc
                    icon='DeliveryTruck'
                    header='Vendor Management'
                    route={'/settings/vendors'}
                    secondaryHeader='Manage the display of vendors'
                    compact
                />}
                {canCatalogModify && <SettingsNavDoc
                    icon='ProductCatalog'
                    header='Catalog Management'
                    route={'/settings/catalogs'}
                    secondaryHeader='Create and manage product catalogs'
                    status={catalogs.isLoading ? 'Loading...' : `${catalogs.data?.length} of ${organization?.catalogLimit ?? 1} catalogs`}
                    compact
                />}
                <SettingsNavDoc
                    icon='AccountManagement'
                    header='User Management'
                    route={'/settings/users'}
                    secondaryHeader='Create and manage users'
                    status={organization ? `${organization.licensedUserCount} of ${organization.userLimit} users` : undefined}
                    compact
                />
                {canTaxCodeModify && <SettingsNavDoc
                    icon='Money'
                    header='Tax Code Management'
                    route={'/settings/taxCodes'}
                    secondaryHeader='Create and manage internal & imported tax codes'
                    status={taxCodes.isLoading ? 'Loading...' : `${taxCodes.data?.length} tax codes`}
                    compact
                />}
                {organization?.subOrganizationsEnabled && <SettingsNavDoc
                    icon='Org'
                    header='Sub-Org Management'
                    route={'/settings/subOrganizations'}
                    secondaryHeader='Create and manage sub-organizations'
                    status={subOrganizations.isLoading ? 'Loading...' : `${subOrganizations.data?.length} sub-organizations`}
                    compact
                />}
                <SettingsNavDoc
                    icon='ColumnOptions'
                    header='Custom Column Management'
                    route={'/settings/columns'}
                    secondaryHeader='Manage custom column sets used for document generation'
                    compact
                />
                {canStorefrontAdmin && <SettingsNavDoc
                    icon='ShoppingCart'
                    header='Storefront Management'
                    route='/settings/storefront'
                    secondaryHeader='Manage storefronts'
                    compact
                    />}
                <SettingsNavDoc
                    icon='Contact'
                    header='Contact Management'
                    route='/settings/contacts'
                    secondaryHeader='Manage contacts'
                    compact
                />
            </Stack>
            <h2>Extensions</h2>
            <Stack tokens={{childrenGap: '1em'}} horizontal wrap>
                {(canOrgExtensionModify || canUserExtension) && <SettingsNavDoc
                    icon='Link'
                    header='Extension Management'
                    route={'/settings/extensions'}
                    secondaryHeader='Manage installed extensions'
                    status={extensions.isLoading ? 'Loading...' : `${extensions.data?.length} installed extensions`}
                    compact
                />}
                <SettingsNavDoc
                    icon='Lock'
                    header='API Keys'
                    route={'/settings/apikeys'}
                    secondaryHeader='Manage API used for 3rd party inbound integrations'
                    status={apiKeys.isLoading ? 'Loading...' : `${apiKeys.data?.length} API keys`}
                    compact
                />
            </Stack>
            {reportingEnabled && <>
                <h2>Reporting</h2>
                {organization?.apiUsageBillingEnabled && <SettingsNavDoc
                    icon='ReportDocument'
                    header='API Usage'
                    route={'/settings/reporting/api'}
                    secondaryHeader='View your API Usage'
                    compact
                />}
            </>}
            <Footer className={'powered-by-footer-absolute'}/>
        </div>
    )
}

interface ISettingsNavSquareProps {
    icon: string;
    header: string;
    route: string;
    secondaryHeader?: string;
    status?: string;
    compact?: boolean;
}

const SettingsNavDoc: React.FC<ISettingsNavSquareProps> = (props) => {
    const history = useHistory();
    const theme = getTheme();
    const { palette, fonts } = theme;
    const nav = () => {
        history.push(props.route);
    }
    return (
        <DocumentCard
            onClick={nav}
            type={props.compact ? DocumentCardType.compact : DocumentCardType.normal}
            style={{
                width: props.compact ? '100%' : '300px',
                maxWidth: props.compact ? '500px' : undefined,
            }}
        >
            <DocumentCardPreview
                previewImages={[{
                    previewIconProps: {
                        iconName: props.icon,
                        styles: {
                            root: {
                                fontSize: fonts.superLarge.fontSize,
                                color: '#0078d7',
                                backgroundColor: palette.neutralLighterAlt,
                            },
                        },
                    },
                    width: props.compact ? 75 : undefined,
                    height: props.compact ? undefined : 200,
                }]}
            />
            <DocumentCardDetails>
                <DocumentCardTitle title={props.header} styles={{root: {fontWeight: 'bold'}}}/>
                {props.secondaryHeader && <DocumentCardTitle title={props.secondaryHeader} showAsSecondaryTitle className={classes.secondaryHeader} /> }
                {props.status && <DocumentCardStatus status={props.status} />}
            </DocumentCardDetails>
        </DocumentCard>
    )
}

export default SettingsHomePage;