import React from 'react';
import { useGetAllVendorSettingsQuery } from '../../store/Api';
import { Dropdown, IDropdownOption, ISelectableOption, Label, TextField } from '@fluentui/react';

interface IVendorSelectionDropdownProps {
    selectedVendor?: string;
    onChange: (vendor: string) => void;
    allowCustom?: boolean;
    label?: string;
    disabled?: boolean;
    onBlur?: () => void;
}

const CUSTOM_VENDOR_KEY = 'CUSTOM';

const VendorSelectionDropdown: React.FC<IVendorSelectionDropdownProps> = (props) => {
    const vendors = useGetAllVendorSettingsQuery();
    const [selectedVendor, setSelectedVendor] = React.useState<string | undefined>(props.selectedVendor);
    const [options, setOptions] = React.useState<ISelectableOption[]>([]);
    const [customVendor, setCustomVendor] = React.useState<string>('');
    const [customEnabled, setCustomEnabled] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!vendors.data) return;
        setOptions([
            ...(vendors.data?.items.map(c => ({key: c.sourceVendor, text: c.vendorDisplayName ?? c.sourceVendor})) ?? []),
            ...(props.allowCustom ? [{key: CUSTOM_VENDOR_KEY, text: 'Custom'}] : [])
        ]);
    }, [vendors.data]);

    React.useEffect(() => {
        if (vendors.isLoading) return;
        if (!customEnabled && vendors.data?.items.find(o => o.sourceVendor === props.selectedVendor)) setSelectedVendor(props.selectedVendor);
        else if(props.selectedVendor) {
            setCustomEnabled(true);
            setCustomVendor(props.selectedVendor)
        }
    }, [props.selectedVendor, vendors.isLoading, vendors.data]);

    React.useEffect(() => {
        if (customEnabled) {
            props.onChange(customVendor);
        } else {
            props.onChange(selectedVendor ?? '');
            props.onBlur?.();
        }
    }, [selectedVendor, customVendor, customEnabled]);

    const updateDropdown = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setSelectedVendor(option?.key?.toString() ?? '');
        if (option?.key !== CUSTOM_VENDOR_KEY) {
            props.onChange(option?.key?.toString() ?? '');
            setCustomEnabled(false);
        } else {
            setCustomEnabled(true);
        }
    }

    return <div>
        <Label>{props.label ?? 'Vendor'}</Label>
        <Dropdown
            disabled={!vendors.data || props.disabled}
            options={options}
            selectedKey={customEnabled ? 'CUSTOM' : props.selectedVendor}
            onChange={updateDropdown}
        />
        {customEnabled && !vendors.isLoading && <div style={{paddingTop: '1em'}}>
            <TextField
                disabled={!vendors.data || props.disabled}
                value={customVendor}
                onChange={(e, v) => setCustomVendor(v ?? '')}
                onBlur={props.onBlur}
            />
        </div>}
    </div>;
}

export default VendorSelectionDropdown;