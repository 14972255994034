import React, {useEffect, useState} from 'react';

import {
    useGetCatalogsQuery,
    useGetOrganizationsQuery,
    useSetSelectedOrganizationMutation,
    useUpdateOrganizationMutation
} from "../../store/Api";
import {CommandBar, Dropdown, ICommandBarItemProps, IDropdownOption, Label, MessageBar, MessageBarType, PrimaryButton, Stack, TextField} from "@fluentui/react";
import EditableImage from "../../components/DetailsModal/EditableImage";
import CountrySelection from "../../components/CountrySelection/CountrySelection";
import {IOrganization} from "../../models/Settings";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { isEqual } from 'lodash';
import { IsMutationSuccess } from '../../logic/MutationTypeChecker';
import { IActionResponse } from '../../dal/BaseDAL';

const OrganizationManagement: React.FC = () => {
    const selectedOrg = useSelector((state: RootState) => state.settings.selectedOrganization);
    const organizations = useGetOrganizationsQuery();
    const [setGlobalOrg] = useSetSelectedOrganizationMutation();
    const catalogs = useGetCatalogsQuery();
    const [updateOrg] = useUpdateOrganizationMutation();
    const [saving, setSaving] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const [organization, setOrganization] = useState<IOrganization | undefined>();

    useEffect(() => {
        if (selectedOrg) {
            setOrganization(() => selectedOrg);
        } else if (organizations.data?.length) {
            setOrganization(() => organizations.data?.[0]);
            setGlobalOrg(organizations.data?.[0]);
        }
    }, [selectedOrg, organizations.data]);

    useEffect(() => {
        if (isEqual(organization, selectedOrg)) setUpdated(false);
        else setUpdated(true);
    }, [organization, selectedOrg]);

    const save = async () => {
        if (organization == null) return;
        setSaving(true);
        setError(undefined);
        const result = await updateOrg(organization)
        if (IsMutationSuccess<IActionResponse>(result)) {
            if (!result.data.success) {
                setError(result.data.message ?? 'An error occurred');
            }
        } else {
            setError('An unknown error occurred');
        }
        setSaving(false);
    }

    const handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event.currentTarget.name == null) return;
        if (organization == null) return;
        setOrganization({
            ...organization,
            [event.currentTarget.name]: newValue ?? ''
        });
    }

    const handleLogoChange = (id: string, url: string) => {
        if (organization == null) return;
        setOrganization({
            ...organization,
            logo: url
        });
    }

    const handleCountryChange = (country: string) => {
        if (organization == null) return;
        setOrganization({
            ...organization,
            country
        });
    }

    const handleDefaultCatalogChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (organization == null) return;
        setOrganization({
            ...organization,
            defaultCatalog: option?.key as string
        });
    }

    const farCommands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Refresh',
            iconOnly: true,
            iconProps: {iconName: 'Refresh'},
            onClick: () => {
                organizations.refetch().then(() => {
                    setGlobalOrg(organizations.data?.[0])
                });
                catalogs.refetch();
            }
        }
    ]

    return (
        <div>
            <h1>Organization Management</h1>
            <CommandBar items={[]} farItems={farCommands} />
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            <Stack tokens={{childrenGap: '1em'}}>
                <TextField
                    label='Organization Name'
                    value={organization?.name}
                    name='name'
                    onChange={handleChange}
                />
                <div>
                    <Label>Organization Logo</Label>
                    <EditableImage
                        image={{
                            url: organization?.logo ?? '',
                            type: 'Custom',
                            name: organization?.name ?? '',
                            id: 'logo',
                            isCustom: true,
                        }}
                        editMode={true}
                        onChange={handleLogoChange}
                    />
                </div>
                {organization?.procurementEnabled && <div className={"filtered-field-container"}>
                    <h3>Procurement Defaults</h3>
                    <h4>Address</h4>
                    <TextField
                        label='Contact 1'
                        value={organization.contact1}
                        name='contact1'
                        onChange={handleChange}
                    />
                    <TextField
                        label='Contact 2'
                        value={organization.contact2}
                        name='contact2'
                        onChange={handleChange}
                    />
                    <TextField
                        label='Address 1'
                        value={organization.address1}
                        name='address1'
                        onChange={handleChange}
                    />
                    <TextField
                        label='Address 2'
                        value={organization.address2}
                        name='address2'
                        onChange={handleChange}
                    />
                    <TextField
                        label='City'
                        value={organization.city}
                        name='city'
                        onChange={handleChange}
                    />
                    <TextField
                        label='State / Province'
                        value={organization.stateProvince}
                        name='stateProvince'
                        onChange={handleChange}
                    />
                    <TextField
                        label='Zip / Postal Code'
                        value={organization.postalCode}
                        name='postalCode'
                        onChange={handleChange}
                    />
                    <TextField
                        label='Contact Email'
                        value={organization.contactEmail}
                        name='contactEmail'
                        onChange={handleChange}
                    />
                    <TextField
                        label='Contact Phone Number'
                        value={organization.contactPhone}
                        name='contactPhone'
                        onChange={handleChange}
                    />
                </div>}
                <CountrySelection value={organization?.country} onChange={handleCountryChange}/>
                <Dropdown
                    label={'Default Catalog'}
                    options={catalogs.data?.map((c) => ({key: c.name, text: c.description})) ?? []}
                    selectedKey={organization?.defaultCatalog}
                    onChange={handleDefaultCatalogChange}
                />
                <TextField
                    label='Punchout Currency'
                    value={organization?.punchoutCurrency}
                    name='punchoutCurrency'
                    onChange={handleChange}
                />
                <div>
                    <PrimaryButton
                        text={saving ? 'Saving' : 'Save'}
                        onClick={save}
                        disabled={saving || !updated}
                    />
                </div>
            </Stack>
        </div>
    );
}

export default OrganizationManagement;