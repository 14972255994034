import React, {useEffect, useState} from 'react';
import {IWorkspaceItem} from "../../models/Workspace";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import { useGetAllVendorSettingsQuery } from '../../store/Api';
import { renderVendorNameFromAllSettings } from '../../logic/Product';

interface IVendorSourceSelectionProps {
    item: IWorkspaceItem;
    selectedSource?: string;
    onChange?: (value: string) => void;
}

const ProcurementVendorSourceSelection: React.FC<IVendorSourceSelectionProps> = (props) => {
    const vendorSettings = useGetAllVendorSettingsQuery();

    const [currentSource, setCurrentSource] = useState<string>()
    useEffect(()=> {
        if(props.selectedSource){
            setCurrentSource(props.selectedSource);
        }
    }, [props.item, props.selectedSource])
    return <Dropdown
        selectedKey={currentSource}
        options={[
            ...(props.item.product?.vendors?.map(vendor => (
                {
                    key: vendor.vendorName,
                    text: `${renderVendorNameFromAllSettings(vendor.vendorName, vendorSettings.data)}${vendor.inStock ? '' : ' (Out of Stock)'} - ${(vendor?.cost ?? 0).toFixed(2) ?? ''}`
                }
            )) ?? [])
        ]}
        onChange={(e,d) => {
            if (d) {
                setCurrentSource(d.key as string);
                if(props.onChange) {
                    props.onChange(d.key as string);
                }
            }
        }}
    />
}

export default ProcurementVendorSourceSelection;