import React from 'react';
import { IStorefrontCustomTheme } from '../../../models/Storefront';
import { useSaveStorefrontCustomThemeMutation } from '../../../store/Api';
import {DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack, TextField} from '@fluentui/react';
import { IsMutationSuccess } from '../../../logic/MutationTypeChecker';
import { IActionResponse } from '../../../dal/BaseDAL';
import CompactColorPicker from '../../Inputs/CompactColorPicker';
import FileUpload, { FileUploadType } from '../../ExtensionModal/FileUpload';
import FontPicker from '../../Inputs/FontPicker';

interface IStorefrontThemePanelProps {
    isOpen: boolean;
    onDismiss: () => void;
    theme?: IStorefrontCustomTheme;
}

const StorefrontThemePanel: React.FC<IStorefrontThemePanelProps> = (props) => {
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    const [saving, setSaving] = React.useState(false);
    const [theme, setTheme] = React.useState<IStorefrontCustomTheme>(props.theme ?? {name: ''});
    const [saveTheme] = useSaveStorefrontCustomThemeMutation();

    React.useEffect(() => {
        setTheme(props.theme ?? {name: ''});
    }, [props.theme]);

    const save = async () => {
        setSaving(true);
        const result = await saveTheme({theme});
        if (IsMutationSuccess<IActionResponse>(result)) {
            if (result.data.success) {
                props.onDismiss();
            } else {
                setErrorMessage(result.data.message);
            }
        }
        setSaving(false);
    }

    const header = () => (
        <div style={{flexGrow: 1, paddingLeft: '25px', background: 'white'}}>
            <h1>{props.theme ? 'Edit' : 'New'} Theme</h1>
            {errorMessage &&
                <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>
            }
        </div>
    )

    const footer = () => (
        <Stack horizontal tokens={{childrenGap: '1em'}}>
            <PrimaryButton disabled={saving} onClick={save}>Save</PrimaryButton>
            <DefaultButton disabled={saving} onClick={props.onDismiss}>Cancel</DefaultButton>
        </Stack>
    )


    return (
        <Panel
            type={PanelType.medium}
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            closeButtonAriaLabel='Close'
            isFooterAtBottom={true}
            onRenderHeader={header}
            onRenderFooterContent={footer}
        >
            <TextField label='Name' value={theme.name} onChange={(e, v) => setTheme({...theme, name: v ?? ''})} />
            <CompactColorPicker label='Menu Bar Color' color={theme.menuBarColor ?? '#fff'} onChange={(c) => setTheme({...theme, menuBarColor: c})} />
            <CompactColorPicker label='Menu Bar Text Color' color={theme.menuBarTextColor ?? '#000'} onChange={(c) => setTheme({...theme, menuBarTextColor: c})} />
            <CompactColorPicker label='Background Color' color={theme.backgroundColor ?? '#fff'} onChange={(c) => setTheme({...theme, backgroundColor: c})} />
            <CompactColorPicker label='TextColor' color={theme.textColor ?? '#000'} onChange={(c) => setTheme({...theme, textColor: c})} />
            <FontPicker label='Header Font' selectedFont={theme.headerFont} onChange={(v) => setTheme({...theme, headerFont: v})} />
            <FontPicker label='Default Font' selectedFont={theme.defaultFont} onChange={(v) => setTheme({...theme, defaultFont: v})} />
            <h4>Advanced Theme Options</h4>
            <FileUpload label='Custom CSS' existingFile={theme.customCSS} onSuccess={(v) => setTheme({...theme, customCSS: v})} fileUploadType={FileUploadType.StorefrontCSS} />
        </Panel>
    );
}

export default StorefrontThemePanel;