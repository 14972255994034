import React from 'react';
import {INavLink, INavLinkGroup, INavStyles, mergeStyleSets, Nav, Stack, StackItem} from "@fluentui/react";
import { useHistory } from "react-router-dom";
import CAN, {CATALOG_MODIFY, ORG_EXTENSION_MODIFY, ORG_MODIFY, STOREFRONT_ADMIN, TAX_CODE_MODIFY} from "../../permissions/ability";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import Footer from "../Layout/Footer";

const SettingsLayout: React.FC = (props) => {
    const selectedOrganization = useSelector((state: RootState) => state.settings.selectedOrganization);

    const history = useHistory();
    const navStyles: Partial<INavStyles> = { root: { width: 300 }};
    const canOrgModify = CAN(ORG_MODIFY);
    const canCatalogModify = CAN(CATALOG_MODIFY);
    const canTaxCodeModify = CAN(TAX_CODE_MODIFY);
    const canStorefrontAdmin = CAN(STOREFRONT_ADMIN);

    const classes = mergeStyleSets({
        sideBar: {
            '@media(max-width: 600px)': {
                display: 'none',
            }
        }
    });

    const reportingEnabled = selectedOrganization?.apiUsageBillingEnabled;

    const orgNavLinks: INavLink[] = [];
    
    if (canOrgModify) {
        orgNavLinks.push({
            key: 'Organization',
                name: 'Organization',
            icon: 'CityNext2',
            url: '/settings/organization',
        });
    }
    orgNavLinks.push({
        key: 'Search Settings',
        name: 'Search Settings',
        icon: 'Search',
        url: '/settings/search',
    })
    if (canOrgModify) {
        orgNavLinks.push({
            key: 'Vendor Management',
            name: 'Vendor Management',
            icon: 'DeliveryTruck',
            url: '/settings/vendors',
        })
    }
    if (canCatalogModify) {
        orgNavLinks.push({
            key: 'Catalog Management',
            name: 'Catalog Management',
            icon: 'ProductCatalog',
            url: '/settings/catalogs',
        });
    }
    orgNavLinks.push({
        key: 'User Management',
        name: 'User Management',
        icon: 'AccountManagement',
        url: '/settings/users',
    });
    if(canTaxCodeModify) {
        orgNavLinks.push({
            key: 'Tax Code Management',
            name: 'Tax Code Management',
            icon: 'Money',
            url: '/settings/taxCodes',
        });
    }
    orgNavLinks.push({
        key: 'Custom Column Management',
        name: 'Custom Column Management',
        icon: 'ColumnOptions',
        url: '/settings/columns',
    })

    if (selectedOrganization?.subOrganizationsEnabled === true) {
        orgNavLinks.push({
            key: 'Sub Organization Management',
            name: 'Sub Organization Management',
            icon: 'Org',
            url: '/settings/subOrganizations',
        });
    }
    if (canStorefrontAdmin) {
        orgNavLinks.push({
            key: 'Storefront Admin',
            name: 'Storefront Admin',
            icon: 'Shop',
            url: '/settings/storefront',
        });
    }
    orgNavLinks.push({
        key: 'Contact Management',
        name: 'Contact Management',
        icon: 'Contact',
        url: '/settings/contacts',
    });
    
    const extensionManagementLink: INavLink = {
            key: 'Extension Management',
            name: 'Extension Management',
            icon: 'Link',
            url: '/settings/extensions',
    }
    const apiKeysLink: INavLink = {
        key: 'API Keys',
        name: 'API Keys',
        icon: 'Lock',
        url: '/settings/apikeys',
    };
    const extensionNavLinks: INavLink[] = CAN(ORG_EXTENSION_MODIFY)  ? 
        [extensionManagementLink, apiKeysLink ] : 
        [apiKeysLink];
    const navLinkGroups: INavLinkGroup[] = [
        {
            name: 'Organization',
            links: orgNavLinks,
        },
        {
            name: 'Extensions',
            links: extensionNavLinks,
        },
    ];
    if (reportingEnabled) {
        navLinkGroups.push({
            name: 'Reporting',
            links: [
                {
                    key: 'API Usage',
                    name: 'API Usage',
                    icon: 'ReportDocument',
                    url: '/settings/reporting/api',
                },
            ]
        });
    }
    return (<>
        <Stack horizontal tokens={{childrenGap: '1em', padding: '1em'}}>
            <StackItem className={classes.sideBar}>
                <Nav
                    groups={navLinkGroups}
                    styles={navStyles}
                    onLinkClick={(e, l) => {
                        e?.preventDefault();
                        if (l?.url)
                            history.push(l?.url);
                    }}
                />
                
            </StackItem>
            <StackItem grow>
                {props.children}
            </StackItem>
        </Stack>
        <Footer className={'powered-by-footer-settings-page'}/>
        </>
    )
}

export default SettingsLayout;