import React, {useEffect, useState} from "react";
import {
    useGetProcurementSourceVendorsQuery,
    useGetProcurementItemsBySourceVendorQuery,
    usePostProcurementProductMutation,
    usePutProcurementProductMutation, 
    useRefreshProcurementPricingMutation,
    useRemoveProcurementProductMutation
} from "../../store/Api";
import {IProcurementItem} from "../../models/Order";
import {
    CommandBar,
    DetailsHeader,
    DetailsRowCheck,
    IColumn,
    ICommandBarItemProps,
    IDetailsListProps, 
    Pivot, 
    PivotItem,
    Selection,
    SelectionMode, 
    ShimmeredDetailsList
} from "@fluentui/react";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {renderPrepareOrderColumns} from "../../logic/Columns/ProcurementItemColumns";
import OrderModal from "../../components/Procurement/OrderModal"
import ProcurementItemDetailsModal from "../../components/Procurement/ProcurementItemDetailsModal";
import Sticky from "react-stickynode";
import {cloneDeep} from "lodash";
import Footer from "../../components/Layout/Footer";


const PrepareOrdersPage: React.FC = () => {
    const [sourceVendors, setSourceVendors] = useState<string[]>()
    const [selectedSourceVendor, setSelectedSourceVendor] = useState<string>()

    const sourceVendorsApi = useGetProcurementSourceVendorsQuery();
    const vendorItems = useGetProcurementItemsBySourceVendorQuery({sourceVendor: selectedSourceVendor ?? ''}, 
        {skip: selectedSourceVendor == undefined || selectedSourceVendor == ''});
    const [createItem] = usePostProcurementProductMutation()
    const [updateItem] = usePutProcurementProductMutation()
    const [refresh] = useRefreshProcurementPricingMutation();
    const [removeProcurementProduct] = useRemoveProcurementProductMutation();

    const [orderModalVisible, setOrderModalVisible] = useState<boolean>();
    const [selectedItems, setSelectedItems] = useState<IProcurementItem[]>()
    const [detailItem, setDetailItem] = useState<IProcurementItem>()
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>();
    const [allItems, setAllItems] = useState<IProcurementItem[]>([])
    const [myItemsOnly, setMyItemsOnly] = useState<boolean>()
    const [ignoreSelectionClick, setIgnoreSelectionClick] = useState<boolean>(false)
    const user = useSelector((state: RootState) => state.auth.userName);
    const [columns, setColumns] = useState<IColumn[]>([])

    const [refreshing, setRefreshing] = useState<boolean>()

    const [refreshError, setRefreshError] = useState<string>()


    useEffect(() => {
        if(vendorItems.data){
            if(myItemsOnly && user){
                const filteredItems = vendorItems.data.filter(a => a.username == user)
                setAllItems(filteredItems)
            }
            else {
                setAllItems(vendorItems.data)
            }
        }
    }, [vendorItems.data, myItemsOnly, selectedSourceVendor]);

/*    useEffect(() => {
        if(myItemsOnly && user){
            const filteredItems = allItems.filter(a => a.username == user)
            setAllItems(filteredItems)
        }
    }, [myItemsOnly]);*/
    useEffect(() => {
        const tempColumns = renderPrepareOrderColumns((item) => {
            setDetailItem(item)
            setShowDetailsModal(true)
        })
        setColumns(tempColumns)
    }, [allItems]);

    useEffect(() => {
        if(sourceVendorsApi.data){
            setSourceVendors(sourceVendorsApi.data)
            if((selectedSourceVendor == undefined || selectedSourceVendor == '') && sourceVendorsApi.data.length > 0){
                setSelectedSourceVendor(sourceVendorsApi.data[0])
            }
        }
    }, [sourceVendorsApi.data]);
    const commands: ICommandBarItemProps[] = [
        {
            key: 'Order',
            text: ((selectedItems != undefined && selectedItems.length > 0) ?'Start Order' : 'Select Products For Order'),
            iconProps: { iconName: 'DeliveryTruck' },
            disabled: (!(selectedItems != undefined && selectedItems.length > 0)),
            onClick: () => {
                setOrderModalVisible(true);
            }
        },
        {
            key: 'Scope',
            text: myItemsOnly ? 'Show All' : 'My Products Only',
            iconProps: {iconName: myItemsOnly? 'ClearFilter' : 'Filter' },
            onClick: () => {
                if(myItemsOnly){
                    setMyItemsOnly(false)
                }
                else{
                    setMyItemsOnly(true)
                }
            }
        }
    ]
    const pluralized = (count: number, noun: string) => {
        return count >= 2 || count === 0 ? `${noun}s` : noun;
    }
    
    const splitItem = (item: IProcurementItem) => {
        if(item.quantity >= 2) {
            const newItem = cloneDeep(item)
            const updatedItem = cloneDeep(item)
            newItem.id = undefined
            const currentQuantity = item.quantity;
            const remainder = currentQuantity % 2
            const halfsies = Math.trunc(currentQuantity / 2)
            updatedItem.quantity = halfsies + remainder
            newItem.quantity = halfsies;
            createItem({request: newItem, adjacentItem: updatedItem.id})
            if (updatedItem.id) {
                updateItem({procurementProductId: updatedItem.id, request: updatedItem})
            }
        }
    }
    
    const farCommands: ICommandBarItemProps[] = [
        { 
            key: 'refresh',
            disabled: refreshing || !allItems,
            text: (refreshing ? 'Refreshing' : 'Refresh Product Price and Availability'), 
            iconProps: { iconName: 'Refresh' }, 
            onClick: () => {
                if(selectedSourceVendor) {
                    setRefreshing(true)
                    refresh({sourceVendor: selectedSourceVendor}).unwrap().then((a) => {
                        if (!a.items) {
                            setRefreshError(a.warning)
                            setRefreshing(false)
                            return
                        }
                        const updatedItems = cloneDeep(allItems)
                        if(a.items) {
                            updatedItems.forEach(ui => {
                                const correspondingItemIndex = a.items?.findIndex(a => a.id == ui.id)
                                if (correspondingItemIndex && correspondingItemIndex != -1 && a?.items?.[correspondingItemIndex]) {
                                    ui = a.items[correspondingItemIndex]
                                }
                            })
                        }
                        setAllItems(updatedItems)
                        setRefreshing(false)
                    })
                }
     
            }, iconOnly: false 
        },
        {
            key: 'remove',
            text: selectedItems && selectedItems.length > 0 ? `Cancel ${selectedItems.length} ${pluralized(selectedItems.length, 'product')}` : 'No products selected',
            disabled: !selectedItems || selectedItems?.length === 0,
            iconProps: {iconName: 'Delete'},
            onClick: () => {
                if (selectedItems) {
                    for (const item of selectedItems) {
                        if (item?.id && item?.source) {
                            removeProcurementProduct({procurementProductId: item.id, procurementSource: item.source});
                        }
                    }
                    setSelectedItems([]);
                }
            }
        },
        {
            key: 'split',
            text: 'Split',
            disabled: !selectedItems || selectedItems.length != 1,
            iconProps: { iconName: 'Split' },
            onClick: () => {
                if(selectedItems) {
                    splitItem(selectedItems?.[0])
                }
            }
        },
    ]
    const itemsChangedHandler = () => {
        const initialSelection = selection.getSelection() as IProcurementItem[]
        for (const { key } of selection.getItems()) {
            if(key) {
                initialSelection.forEach((v, i) => {
                    if(v.source != initialSelection?.[-1]?.source){
                        selection.setKeySelected(key.toString(), false, false);
                    }
                })
            }
        }
    };
    const selection = new Selection({
        onSelectionChanged: () => {
            const initialSelection = selection.getSelection() as IProcurementItem[]
            const selectionIndexes = selection.getSelectedIndices()
            const lastItemSource = initialSelection?.at(-1)?.source
            if(lastItemSource && !ignoreSelectionClick){
                setSelectedItems(initialSelection.filter(s => s.source === lastItemSource))
                initialSelection.forEach((v, i) => {
                    if(v.source != lastItemSource){
                        selection.setIndexSelected(selectionIndexes[i], false, false)
                    }
                })
            }
            else{
                setSelectedItems(initialSelection)
                setIgnoreSelectionClick(false)
            }

        },
        onItemsChanged: itemsChangedHandler

    })

    const _onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = (props) => {
        if (props) {
            return (<Sticky enabled={true} top={80} innerZ={10}><DetailsHeader {...props} ariaLabelForToggleAllGroupsButton={'Toggle selection'} onRenderDetailsCheckbox={(a, b)=> {
                const selectFix = document.getElementsByClassName('ms-DetailsHeader-cellIsCheck');
                if(selectFix?.[0]) {
                    const replacement = selectFix?.[0]?.cloneNode(true)
                    selectFix?.[0]?.replaceWith(replacement)
                }
                return <span data-selection-disabled={true}><div onClick={() => setIgnoreSelectionClick(true)}><DetailsRowCheck canSelect={false} onClick={() => setIgnoreSelectionClick(true)}></DetailsRowCheck></div></span>
            }}/></Sticky>);
        }
        return null;
    };
    
    const sourceVendorTabs = sourceVendors?.map((sourceVendor) =>
        <PivotItem headerText={sourceVendor}>
            <Sticky enabled={true} top={42} innerZ={10}>
                <CommandBar items={commands} farItems={farCommands}/></Sticky>
            <ShimmeredDetailsList
                items={allItems ?? []}
                enableShimmer={vendorItems.isLoading || vendorItems.isFetching}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                selectionPreservedOnEmptyClick={true}
                selection={selection}
                onRenderDetailsHeader={_onRenderDetailsHeader}
            />
        </PivotItem>
    )
    
    return (
        <div className="main-content">
            <h1>Prepare Orders</h1>


            <Pivot
                overflowBehavior='menu' styles={{itemContainer: {marginTop: '1em'}}}
                onLinkClick={(item, ev) => {setSelectedSourceVendor(item?.props.headerText)}}
            >
                {sourceVendorTabs}
            </Pivot>
            
            <OrderModal
                displayModal={orderModalVisible === true}
                selectedItems={selectedItems}
                selectedCompany={selectedItems?.[0]?.company ?? ''}
                closer={() => setOrderModalVisible(false)}
                onChange={() => console.log('')}
                vendor={selectedItems?.[0]?.source ?? ""}
            />
            {detailItem && 
            <ProcurementItemDetailsModal 
                product={detailItem} 
                open={showDetailsModal === true} 
                onClose={() => {
                    setDetailItem(undefined)
                    setShowDetailsModal(false)}}
            >
            </ProcurementItemDetailsModal>}
            <Footer className={'powered-by-footer-absolute'}/>
        </div>
    );
}

export default PrepareOrdersPage;
