import React from "react";

interface IFooterProps{
    className?: string;
}

const Footer: React.FC<IFooterProps> = (props) => {
    const whiteLabel = window.config.overrideLogo != null;

    return (
        <div>
        {whiteLabel ?
            <div className={props.className ?? 'powered-by-footer-fixed'}>
                Powered by AdaptiveCatalog
            </div> : <></>
        }
        </div>
    )
}

export default Footer;