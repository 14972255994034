import React, {useEffect, useState} from 'react';
import {
    useEnableFolioSubExtensionMutation,
    useGetExtensionFolioQuery, useValidateExtensionFolioMutation
} from '../../store/Api';
import {IInstalledExtensionFolio} from "../../models/Extension";
import {StorefrontSubExtensionOverrideForm} from "../ExtensionInstallation/ExtensionDataForm";
import {Checkbox, MessageBar, MessageBarType} from "@fluentui/react";
import {IStorefrontSettings} from "../../models/Storefront";

interface IStorefrontOpportunityExtensionForm {
    subExtensionFolioCompositeId?: string;
    settings: IStorefrontSettings;
    opportunityCreationEnabled?: boolean;
    onChange?: (settings: IStorefrontSettings) => void;
    onLoading?: (isLoading: boolean) => void;
}

const StorefrontOpportunityExtensionForm: React.FC<IStorefrontOpportunityExtensionForm> = (props) => {
    const [folioId, setFolioId] = useState<string>();
    const [subExtensionsId, setSubExtensionId]  = useState<string>();
    const extensionFolioApi = useGetExtensionFolioQuery(folioId ?? '', {skip: (!folioId || folioId == '')})
    const validFolio = useValidateExtensionFolioMutation();
    const [enableFolio] = useEnableFolioSubExtensionMutation()
    const [currentFolio, setCurrentFolio] = useState<IInstalledExtensionFolio | undefined>();
    const [error, setError] = useState<string | undefined>();
    const [success, setSuccess] = useState<string | undefined>();


    useEffect(() => {
        const folioIdSplits = props.subExtensionFolioCompositeId?.split('_');
        if(!folioIdSplits || folioIdSplits.length != 3) {
            return;
        }
        setFolioId(folioIdSplits[1]);
        setSubExtensionId(folioIdSplits[2]);
    }, [props.subExtensionFolioCompositeId]);
    useEffect(() => { 
        if(extensionFolioApi.data) {
            console.log('current folio:', extensionFolioApi.data);
            setCurrentFolio((current) => extensionFolioApi.data);
        }
    }, [extensionFolioApi.data]);

    useEffect(() => {
        if(currentFolio?.sourceExtensionFolio?.id && subExtensionsId) {
            if(!currentFolio.enabledSubExtensions?.includes(subExtensionsId)) {
                enableFolio({id: currentFolio.sourceExtensionFolio.id, subExtension: subExtensionsId})

                setCurrentFolio((existing) => ({...existing, enabledSubExtensions: [...existing?.enabledSubExtensions ?? [], subExtensionsId]}));
            }
        }
    }, [currentFolio, subExtensionsId]);
    
    return (
        <>         
            {currentFolio && subExtensionsId &&
            <>
                {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar> }
                {success && <MessageBar messageBarType={MessageBarType.success}>{success}</MessageBar> }
            <h3>Opportunity Details</h3>
            <Checkbox
                label="Disable Opportunity Creation"
                checked={props.settings.disableOpportunityCreation}
                onChange={(e, checked) => {
                    props.onChange?.(checked? {...props.settings, disableOpportunityCreation: true} : {...props.settings, disableOpportunityCreation: false});
                }}
            />
                <br></br>
            {props. opportunityCreationEnabled && !props.settings.disableOpportunityCreation && 
            <StorefrontSubExtensionOverrideForm
                folio={currentFolio}
                subExtension={subExtensionsId}
                settings={props.settings}
                onChange={props.onChange}
                onLoading={props.onLoading}
            />}
        </>
        }
        </>
    );
}

export default StorefrontOpportunityExtensionForm;