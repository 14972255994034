import React, {FC, useCallback, useEffect, useState} from 'react';
import {
    EXTENSION_TYPE_DIRECTIMPORT,
    EXTENSION_TYPE_EXPORT,
    EXTENSION_TYPE_IMPORT, EXTENSION_TYPE_PROCUREMENT,
    EXTENSION_TYPE_REALTIME,
    EXTENSION_TYPE_WEBHOOK,
    IAdditionalData,
    IExtension,
    IExtensionInstallRequest,
    IImportMapping, IInstalledExtensionFolio,
} from "../../models/Extension";
import {
    Checkbox,
    DefaultButton,
    Dropdown, Label, MaskedTextField,
    MessageBar,
    MessageBarType,
    PrimaryButton, SpinButton, Spinner,
    Stack, StackItem,
    TextField
} from "@fluentui/react";
import MappingTable from "./MappingTable";
import {
    useGetCatalogsQuery,
    useInstallExtensionMutation,
    useLazyGetImportSampleQuery,
    usePutExtensionFolioMutation,
    usePutInstalledExtensionMutation,
    useRunImportExtensionsForFolioMutation,
    useValidateExtensionFolioMutation,
    useValidateExtensionSettingsMutation,
    useValidateInstalledExtensionMutation
} from "../../store/Api";
import {IActionResponse} from "../../dal/BaseDAL";
import {IsMutationError, IsMutationSuccess} from "../../logic/MutationTypeChecker";
import ExtensionInstallOption from "./ExtensionInstallOption";
import Scheduler, {ConvertCronToSchedule, ConvertScheduleToCron, ISchedule} from "../Scheduler/Scheduler";
import {cloneDeep, debounce} from "lodash";
import ExtensionFieldSearch from "../ExtensionModal/ExtensionFieldSearch";
import ExtensionDependentOption from "./ExtensionDependentOption";
import {IStorefrontOpportunitySetting, IStorefrontSettings} from "../../models/Storefront";
import DataField from "../Common/DataField";
import VendorSelectionDropdown from '../Vendors/VendorSelectionDropdown';

interface IExtensionInstallFormProps {
    request: IExtensionInstallRequest;
    onChange: (request: IExtensionInstallRequest) => void;
    extension: IExtension;
    isNew?: boolean;
    validationResponse?: IActionResponse;
    saveError?: string;
    installedId?: string;
}

const TargetCatalogExtensions = [EXTENSION_TYPE_IMPORT, EXTENSION_TYPE_REALTIME, EXTENSION_TYPE_WEBHOOK, EXTENSION_TYPE_DIRECTIMPORT];
const VendorExtension = [EXTENSION_TYPE_REALTIME, EXTENSION_TYPE_EXPORT, EXTENSION_TYPE_IMPORT, EXTENSION_TYPE_PROCUREMENT];
const ScheduleExtensions = [EXTENSION_TYPE_IMPORT, EXTENSION_TYPE_PROCUREMENT];
const CreateProductsExtension = [EXTENSION_TYPE_IMPORT];
const defaultSchedule: ISchedule = {days: [0, 1, 2, 3, 4, 5, 6], hour: 1, minute: 0};

const ExtensionInstallForm: FC<IExtensionInstallFormProps> = (props) => {
    const [error, setError] = useState<string | undefined>(undefined);
    //TODO: Mapping table
    return (
        <Stack tokens={{childrenGap: '1em'}}>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            {props.validationResponse && !props.validationResponse.success &&
                <MessageBar messageBarType={MessageBarType.error}>{props.validationResponse.message}</MessageBar>}
            {props.validationResponse && props.validationResponse.success && <MessageBar
                messageBarType={MessageBarType.success}>{props.validationResponse.message ?? 'Extension settings validated'}</MessageBar>}
            {props.saveError && <MessageBar messageBarType={MessageBarType.error}>{props.saveError}</MessageBar>}
            <Dropdown
                options={[{key: 'organization', text: 'Organization'}, {key: 'user', text: 'User'}]}
                label="Extension Level"
                selectedKey={props.request.extensionLevel}
                onChange={(e, d) => props.onChange({...props.request, extensionLevel: d?.key?.toString() ?? ''})}
                disabled={!props.isNew}
            />
            <TextField
                label="Nickname"
                type="text"
                onChange={(e, d) => props.onChange({...props.request, extensionNickname: d ?? ''})}
                value={props.request.extensionNickname}
            />
            <ExtensionStaticDataForm
                staticData={props.request.additionalData}
                onChange={(data) => props.onChange({...props.request, additionalData: data})}
                additionalFields={props.extension.additionalData}
                extensionId={props.extension.id}
            />
            {TargetCatalogExtensions.includes(props.extension.category) && <ExtensionCatalogSelection
                label="Target Catalog"
                catalog={props.request.catalog}
                onChange={(catalog) => props.onChange({...props.request, catalog: catalog})}
            />}
            {VendorExtension.includes(props.extension.category) && <VendorSelectionDropdown
                selectedVendor={props.request.vendor}
                onChange={(vendor) => props.onChange({...props.request, vendor: vendor})}
                allowCustom={true}
                />}
            {props.extension.hasSampleLoad && <ExtensionMappingForm
                mappings={props.request.mappings ?? []}
                onChange={(mappings) => props.onChange({...props.request, mappings: mappings})}
                staticData={props.request.additionalData}
                onError={(message) => setError(message)}
                id={props.extension.id}
                installedId={props.installedId}
            />}
            {ScheduleExtensions.includes(props.extension.category) && <Scheduler
                schedule={props.request.schedule != null ? ConvertCronToSchedule(props.request.schedule) : defaultSchedule}
                onChange={(s) => props.onChange({
                    ...props.request,
                    schedule: ConvertScheduleToCron(s ?? defaultSchedule)
                })}
            />}
        </Stack>
    );
}

interface ISubExtensionInstallFormProps {
    subExtension: string;
    folio?: IInstalledExtensionFolio;
    label?: string;
    onUpdateStart?: () => void;
    onUpdateStop?: () => void;
    onSetError?: (error?: string) => void;
}


export const SubExtensionInstallForm: FC<ISubExtensionInstallFormProps> = (props) => {
    const [folioUpdater] = usePutExtensionFolioMutation()
    const updateExtensionFolio = (data: { [p: string]: string }) => {
        const existingFolio = cloneDeep(props.folio)
        if (existingFolio?.subExtensionInstalledFields && props.subExtension && existingFolio.sourceExtensionFolio?.id) {
            props?.onUpdateStart?.()
            existingFolio.subExtensionInstalledFields[props.subExtension] = data
            folioUpdater({extensionId: existingFolio.sourceExtensionFolio.id, request: existingFolio}).then(() => {
                props?.onUpdateStop?.()
            })
        }
    }
    const [folioCompositeId, setFolioCompositeId] = useState<string | undefined>(undefined);
    useEffect(() => {
        let error = ''
        props.folio?.sourceExtensionFolio?.subExtensions?.[props.subExtension]?.additionalData?.forEach((field) => {
            const checkValue = props.folio?.subExtensionInstalledFields?.[props.subExtension]?.[field.field]
            if(field.optional == false && (checkValue == undefined || checkValue == '')) {
                error +=`Value required for ${field.description}. `
            }
        })
        props?.onSetError?.(error)
    }, [props.folio?.subExtensionInstalledFields, props.folio?.sourceExtensionFolio?.subExtensions?.[props.subExtension]?.additionalData])
    useEffect(() => {
        if (props.subExtension && props.subExtension != '' && props.folio?.sourceExtensionFolio?.id) {
            setFolioCompositeId(`folio_${props.folio.sourceExtensionFolio.id}_${props.subExtension}`)
        }
    }, [props.subExtension, props.folio]);
    return <>
            {(props?.folio?.id != undefined && folioCompositeId) ?
                <Stack tokens={{childrenGap: '1em'}}>
                    {props.label && props.folio?.subExtensionInstalledFields?.[props.subExtension] && <h3 style={{marginTop: '2em', marginBottom: 0}}>{props.label}</h3>}
                    <ExtensionStaticDataForm
                        showTopicGroups={true}
                        staticData={props.folio?.subExtensionInstalledFields?.[props.subExtension] ?? {}}
                        onChange={updateExtensionFolio}
                        additionalFields={props.folio?.sourceExtensionFolio?.subExtensions?.[props.subExtension]?.additionalData ?? []}
                        extensionId={folioCompositeId ?? ''}
                        updateOnDisengage
                    />
                </Stack> : <></>
            }
    </>
}

interface IStorefrontSubExtensionOverrideFormProps {
    subExtension: string,
    folio?: IInstalledExtensionFolio
    settings: IStorefrontSettings;
    onChange?: (settings: IStorefrontSettings) => void;
    onLoading?: (isLoadign: boolean) => void;
}

export const StorefrontSubExtensionOverrideForm: FC<IStorefrontSubExtensionOverrideFormProps> = (props) => {
    const [effectiveStaticData, setEffectiveStaticData] = useState<{ [p: string]: string }>()
    const [folioCompositeId, setFolioCompositeId] = useState<string | undefined>(undefined);
    useEffect(() => {
        const workingSettings: { [p: string]: string } = {}
        const fields = props?.folio?.subExtensionInstalledFields?.[props.subExtension]
        const sourceFields = props.folio?.sourceExtensionFolio?.subExtensions?.[props.subExtension]?.additionalData
        if (sourceFields) {
            sourceFields.forEach((field) => {
                const existingsSetting = props?.settings?.opportunitySettings?.find((s) => s.name == field.field)
                    ?? {name: field.field, value: fields?.[field.field] ?? field.description}
                workingSettings[existingsSetting?.name ?? field.field] = existingsSetting.value ?? ''
            })
        }
        setEffectiveStaticData(workingSettings)
    }, [props.settings, props.folio])
    const updateStorefrontOpportunitySettings = (data: { [p: string]: string }) => {
        const keys = Object.keys(data)
        const opportunitySettings: IStorefrontOpportunitySetting[] = keys.map((key) => {
            return {name: key, value: data[key]}
        })
        if (props.onChange) {
            props.onChange({...props.settings, opportunitySettings: opportunitySettings})
        }
    }
    useEffect(() => {
        if (props.subExtension && props.subExtension != '' && props.folio?.sourceExtensionFolio?.id) {
            setFolioCompositeId(`folio_${props.folio.sourceExtensionFolio.id}_${props.subExtension}`)
        }
    }, [props.subExtension, props.folio]);
    return (<>
            <Stack tokens={{childrenGap: '1em'}}>
                <ExtensionStaticDataForm
                    showTopicGroups={true}
                    staticData={effectiveStaticData ?? {}}
                    onChange={updateStorefrontOpportunitySettings}
                    additionalFields={props.folio?.sourceExtensionFolio?.subExtensions?.[props.subExtension]?.additionalData ?? []}
                    extensionId={folioCompositeId ?? ''}
                    updateOnDisengage
                    onLoading={props.onLoading}
                />
            </Stack>
        </>
    );
}

interface IExtensionFolioInstallFormProps {
    folio?: IInstalledExtensionFolio
    onSetError?: (error?: string) => void
    onValidateComplete?: (response: IActionResponse) => void;
    onValidateStart?: () => void;
    onUpdateStart?: () => void;
    onUpdateStop?: () => void;

}

export const ExtensionFolioInstallForm: FC<IExtensionFolioInstallFormProps> = (props) => {
    const [validateInstalled] = useValidateExtensionFolioMutation();
    const [folioUpdater] = usePutExtensionFolioMutation();
    const [runImport] = useRunImportExtensionsForFolioMutation();
    
    const [currentFolio, setCurrentFolio] = useState<IInstalledExtensionFolio | undefined>(props.folio);
    const [hasCatalogSubExtension, setHasCatalogSubExtension] = useState<boolean>()
    const [hasVendorSubExtension, setHasVendorSubExtension] = useState<boolean>()
    const [hasScheduleSubExtension, setHasScheduleSubExtension] = useState<boolean>()
    const [hasCreateProductsSubExtension, setHasCreateProductsSubExtension] = useState<boolean>()
    const [needsValidation, setNeedsValidation] = useState<boolean>(true)
    const [importRan, setImportRan] = useState<boolean>(false)

    useEffect(() => {
        setCurrentFolio(props.folio)
        setHasCatalogSubExtension(false)
        setHasVendorSubExtension(false)
        if (props.folio?.sourceExtensionFolio?.subExtensions) {
            setHasScheduleSubExtension(Object.values(props.folio.sourceExtensionFolio.subExtensions).findIndex((se) => se.extensionType === EXTENSION_TYPE_IMPORT) !== -1)
        } else {
            setHasScheduleSubExtension(false)
        }
    }, [props.folio])

    useEffect(() => {
        setNeedsValidation(true)
    }, [props.folio?.commonInstalledFields]);

    useEffect(() => {
        let fieldErrorString = ''
        if (currentFolio?.sourceExtensionFolio?.sharedFields) {
            currentFolio?.sourceExtensionFolio?.sharedFields?.forEach((field) => {
                if (!field.optional && (currentFolio.commonInstalledFields?.[field.field] == undefined || currentFolio.commonInstalledFields?.[field.field] == '')) {
                    fieldErrorString += `${field.description} is required. `
                }
            })
        }
        let catalogExt = false;
        let vendorExt = false;
        let scheduleExt = false;
        let createProductsExt = false;
        if (currentFolio?.sourceExtensionFolio?.subExtensions && currentFolio?.enabledSubExtensions) {
            currentFolio.enabledSubExtensions.forEach((subExtension) => {
                const category = currentFolio.sourceExtensionFolio?.subExtensions?.[subExtension]?.extensionType;
                if (!category) return;
                if (TargetCatalogExtensions.includes(category)) {
                    catalogExt = true
                }
                if (VendorExtension.includes(category)) {
                    vendorExt = true
                }
                if (ScheduleExtensions.includes(category)) {
                    scheduleExt = true
                }
                if (CreateProductsExtension.includes(category)) {
                    createProductsExt = true
                }
            })
        }
        setHasCatalogSubExtension(catalogExt);
        setHasVendorSubExtension(vendorExt);
        setHasScheduleSubExtension(scheduleExt);
        setHasCreateProductsSubExtension(createProductsExt);
        if (props.onSetError) {
            props.onSetError(fieldErrorString)
        }

        if (fieldErrorString === '' && needsValidation) {
            validateExisting().then()
        }
    }, [currentFolio]);

    const validateExisting = async () => {
        if (!props.onValidateComplete) return;
        props?.onValidateStart?.()

        if (props.folio?.id == null || props.folio?.commonInstalledFields == undefined) return;
        if (props.folio.sourceExtensionFolio?.id) {
            const result = await validateInstalled({
                id: props.folio.sourceExtensionFolio.id,
                request: {
                    additionalData: props.folio.commonInstalledFields,
                    folioId: props.folio.id,
                    validationActions: props.folio.enabledSubExtensions
                }
            });

            if (IsMutationSuccess<IActionResponse>(result)) {
                props.onValidateComplete(result.data);
                if (result.data?.success) {
                    setNeedsValidation(false)
                }
            } else {
                props.onValidateComplete({success: false, message: 'Failed to communicate with API', id: ''})
            }
        }
    }

    const debouncedUpdateExtensionFolio = useCallback(
        debounce((data: IInstalledExtensionFolio) => {
            if (data.sourceExtensionFolio?.id) {
                folioUpdater({extensionId: data.sourceExtensionFolio?.id, request: data})
            }
        }, 1000, {leading: false}),
        []
    );
    const updateExtensionFolio = (data: { [p: string]: string }) => {
        if (currentFolio?.id) {
            props?.onUpdateStart?.()
            setCurrentFolio((current) => ({...current, commonInstalledFields: data}))
            debouncedUpdateExtensionFolio({...currentFolio, commonInstalledFields: data})
            props?.onUpdateStop?.()
        }
    }
    const setExtensionLevel = (selection: string) => {
        const existingFolio = cloneDeep(currentFolio)
        if (existingFolio?.sourceExtensionFolio?.id) {
            existingFolio.extensionLevel = selection;

            folioUpdater({extensionId: existingFolio?.sourceExtensionFolio?.id, request: existingFolio})
        }
    }
    const setVendor = (selection?: string) => {
        setCurrentFolio((current) => ({...current, vendor: selection}));
    }
    const setCatalog = (selection?: string) => {
        const existingFolio = cloneDeep(currentFolio)
        if (existingFolio?.sourceExtensionFolio?.id) {
            existingFolio.targetCatalog = selection;
            folioUpdater({extensionId: existingFolio?.sourceExtensionFolio?.id, request: existingFolio})
        }
    }
    const setSchedule = (selection?: string) => {
        const existingFolio = cloneDeep(currentFolio)
        if (existingFolio?.sourceExtensionFolio?.id) {
            existingFolio.schedule = selection;
            folioUpdater({extensionId: existingFolio?.sourceExtensionFolio?.id, request: existingFolio})
        }
    }
    const setCreateProduct = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        const existingFolio = cloneDeep(currentFolio)
        if (existingFolio?.sourceExtensionFolio?.id) {
            existingFolio.createProducts = checked ?? false;
            folioUpdater({extensionId: existingFolio?.sourceExtensionFolio?.id, request: existingFolio})
        }
    }
    return (<>
            {(currentFolio?.id != undefined && currentFolio.commonInstalledFields && currentFolio.sourceExtensionFolio?.sharedFields) ?
                <Stack tokens={{childrenGap: '1em'}}>
                    <Dropdown
                        options={[{key: 'organization', text: 'Organization'}, {key: 'user', text: 'User'}]}
                        label="Extension Level"
                        selectedKey={currentFolio.extensionLevel}
                        onChange={(e, d) => {
                            if (d) {
                                setExtensionLevel(d.key.toString())
                            }
                        }}
                    />
                    {hasCatalogSubExtension && <ExtensionCatalogSelection
                        label="Target Catalog"
                        catalog={currentFolio.targetCatalog}
                        onChange={(catalog) => setCatalog(catalog)}
                    />}
                    {hasVendorSubExtension && <VendorSelectionDropdown
                        selectedVendor={currentFolio.vendor}
                        onChange={setVendor}
                        allowCustom={true}
                        onBlur={() => {debouncedUpdateExtensionFolio(currentFolio)}}
                        />}
                    <ExtensionStaticDataForm
                        staticData={currentFolio.commonInstalledFields}
                        onChange={updateExtensionFolio}
                        additionalFields={currentFolio?.sourceExtensionFolio.sharedFields}
                        extensionId={currentFolio?.id}
                        updateOnDisengage={true}
                    />
                    {hasScheduleSubExtension && <>
                        <h3 style={{marginBottom: 0, marginTop: '2em'}}>Import Schedule</h3>
                        <Scheduler
                            schedule={currentFolio.schedule != null ? ConvertCronToSchedule(currentFolio.schedule) : defaultSchedule}
                            onChange={(s) => setSchedule(ConvertScheduleToCron(s ?? defaultSchedule))}
                        />
                    </>}
                    {hasCreateProductsSubExtension && 
                    <div>
                        <Checkbox
                            label="Create Products"
                            checked={currentFolio.createProducts}
                            onChange={setCreateProduct}
                            />
                        {currentFolio.createProducts &&  <div style={{paddingTop: '1em'}}>
                            <PrimaryButton 
                                text={'Import Now'} 
                                onClick={() => {
                                    setImportRan(true);
                                    runImport({folioId: currentFolio?.sourceExtensionFolio?.id ?? ""}).unwrap().then((result) => {
                                        if(!result.success) {
                                            setImportRan(false)
                                            if(props.onSetError) {
                                                props.onSetError(result.message);
                                            }
                                        }
                                    });
                                }}
                                disabled={importRan}
                            />
                        </div>}
                    </div>}
                </Stack> : <></>
            }</>
    );
}

interface IExtensionStaticDataFormProps {
    staticData: { [key: string]: string }
    onChange?: (data: { [key: string]: string }) => void;
    additionalFields: IAdditionalData[];
    extensionId: string;
    updateOnDisengage?: boolean;
    showTopicGroups?: boolean;
    onLoading?: (isLoading: boolean) => void;
}

const ExtensionStaticDataForm: React.FC<IExtensionStaticDataFormProps> = (props) => {
    const [additionaFieldTopicGroups, setAdditionalFieldsTopicGroup] = useState<{[key: string]: IAdditionalData[]}>();
    const [topicGroups, setTopicGroups] = useState<string[]>([])
    const [hiddenTopicGroups, setHiddenTopicGroups] = useState<string[]>([]);
    const [loadingFields, setLoadingFields] = useState<string[]>([])
    useEffect(() => {
        if(loadingFields.length == 0) {
           props.onLoading?.(false) 
        }
        else{
            props.onLoading?.(true)
        }
    }, [loadingFields]);
    useEffect(() => {
        if (props.additionalFields) {
            const groupedFields = props.additionalFields.reduce((acc, current) => {
                const topic = current.topic ?? "General";
                if (!acc[topic]) {
                    acc[topic] = [];
                }
                if(current.fieldType === 'defaultedOption' || current.fieldType === 'static' || current.fieldType === 'installOption' || current.fieldType === 'flag') {
                    acc[topic].push(current);
                }
                return acc;
            }, {} as { [key: string]: IAdditionalData[] });
            setTopicGroups(Object.keys(groupedFields))
            setAdditionalFieldsTopicGroup(groupedFields);
        }
    }, [props.additionalFields]);
    
    const isDependentFieldAccessible = (field: IAdditionalData) => {
        if (!field.parentField) {
            return true;
        }
        return (props.staticData?.[field.parentField] ?? '') != ''
    }

    const getParentFieldValue = (field: IAdditionalData) => {
        if (!field.parentField) {
            return undefined;
        }
        return props.staticData?.[field.parentField]
    }
    const getRequiredElement = (field: IAdditionalData) => {
        if(!field.optional){
            return <div style={{display: "inline-flex", color: "rgb(164, 38, 44)", paddingLeft: "6px", paddingRight: "12px"}}>*</div>
        }
        return <></>
    }
    return (<div>
    {!props.showTopicGroups && props.additionalFields.map((field) => {
            if (field.fieldFilter != null) {
                switch (field.fieldFilter.filterType) {
                    case 'hideIfNot':
                        if (!(field.fieldFilter.target in props.staticData)) return null;
                        if (props.staticData[field.fieldFilter.target] !== field.fieldFilter.value) return null;
                        break;
                }
            }
            switch (field.fieldType) {
                case 'static':
                    return <ExtensionStaticDataField
                        key={field.field}
                        updateOnDisengage={props.updateOnDisengage}
                        field={field}
                        value={props.staticData[field.field] ?? ''}
                        onChange={(value) => {
                            if (props.staticData[field.field] == value) return
                            if (props.onChange) {
                                const staticData = {...props.staticData};
                                const subOrdinateFields = props.additionalFields.filter((f) => f.parentField === field.field);
                                if (subOrdinateFields.length > 0) {
                                    subOrdinateFields.forEach((f) => {
                                        staticData[f.field] = '';
                                    })
                                }
                                props.onChange({...staticData, [field.field]: value})
                            }
                        }
                        }
                    />
                //Largely used for dynamic fields that can be set external to the extension setup screen.
                case 'defaultedOption':
                    if (field.searchable) {
                        return <React.Fragment key={field.field}>
                            <Label>{field.description}{getRequiredElement(field)}</Label>
                            <ExtensionFieldSearch
                                field={field}
                                initialValue={props.staticData[field.field] ?? ''}
                                onChange={(value) => {
                                    if (props.staticData[field.field] == value) return
                                    if (props.onChange) {
                                        const staticData = {...props.staticData};
                                        const subOrdinateFields = props.additionalFields.filter((f) => f.parentField === field.field);
                                        if (subOrdinateFields.length > 0) {
                                            subOrdinateFields.forEach((f) => {
                                                staticData[f.field] = '';
                                            })
                                        }
                                        props.onChange({...staticData, [field.field]: value})
                                    }
                                }
                                }
                                extensionId={props.extensionId}
                            /></React.Fragment>
                    }

                    return <ExtensionDependentOption
                        key={field.field}
                        parentFieldValue={getParentFieldValue(field)}
                        initialValue={props.staticData[field.field] ?? ''}
                        optionsField={field}
                        onChange={(fieldKey, value) => {
                            if (props.staticData[fieldKey] == value) return
                            if (props.onChange) {
                                const staticData = {...props.staticData};
                                const subOrdinateFields = props.additionalFields.filter((f) => f.parentField === fieldKey);
                                if (subOrdinateFields.length > 0) {
                                    subOrdinateFields.forEach((f) => {
                                        staticData[f.field] = '';
                                    })
                                }
                                props.onChange({...staticData, [field.field]: value})
                            }
                        }
                        }
                        extensionId={props.extensionId}
                        readOnly={!isDependentFieldAccessible(field)}
                        onLoading={(value) => {
                            if(value){
                                setLoadingFields((a) => a.concat(field.field))
                            }
                            else{
                                setLoadingFields((a) => a.filter(f => f!= field.field))
                            }
                        }}
                    />

                case 'installOption':
                    return <ExtensionInstallOption
                        key={field.field}
                        initialValue={props.staticData[field.field] ?? ''}
                        optionsField={field}
                        onChange={(fieldKey, value) => {
                            if (props.staticData[fieldKey] == value) return
                            if (props.onChange) {
                                const staticData = {...props.staticData};
                                const subOrdinateFields = props.additionalFields.filter((f) => f.parentField === fieldKey);
                                if (subOrdinateFields.length > 0) {
                                    subOrdinateFields.forEach((f) => {
                                        staticData[f.field] = '';
                                    })
                                }
                                props.onChange({...staticData, [field.field]: value})
                            }
                        }
                        }
                        extensionId={props.extensionId}
                    />
                case 'flag':
                    return (
                        <div key={`extension-flag-${field.field}`}>
                            <Checkbox checked={props.staticData[field.field] == 'true'}
                                      label={field.description}
                                      onChange={(fieldKey, value) => {
                                          if (props.staticData[field.field] == value?.toString()) return
                                          if (props.onChange) {
                                              const staticData = {...props.staticData};
                                              const subOrdinateFields = props.additionalFields.filter((f) => f.parentField === fieldKey);
                                              if (subOrdinateFields.length > 0) {
                                                  subOrdinateFields.forEach((f) => {
                                                      staticData[f.field] = '';
                                                  })
                                              }
                                              props.onChange({
                                                  ...staticData,
                                                  [field.field]: value?.toString() ?? 'false'
                                              })
                                          }
                                      }
                                      }
                            />
                        </div>
                    );
                default:
                    return null;
            }
        })
    }
    {props.showTopicGroups && additionaFieldTopicGroups && topicGroups.map(group => {
        if(additionaFieldTopicGroups[group].length == 0) {
            return <div></div>
        }
        return(
            <div style={{paddingTop: "10px", paddingBottom: "20px", width: "500px", display: "inline-table", paddingRight: "30px"}}><h3>{group + " "}Settings</h3>

            {additionaFieldTopicGroups[group] && additionaFieldTopicGroups[group].map((field) => {
                if (field.fieldFilter != null) {
                    switch (field.fieldFilter.filterType) {
                        case 'hideIfNot':
                            if (!(field.fieldFilter.target in props.staticData)) return null;
                            if (props.staticData[field.fieldFilter.target] !== field.fieldFilter.value) return null;
                            break;
                    }
                }
                switch (field.fieldType) {
                    case 'static':
                        return <ExtensionStaticDataField
                            key={field.field}
                            updateOnDisengage={props.updateOnDisengage}
                            field={field}
                            value={props.staticData[field.field] ?? ''}
                            onChange={(value) => {
                                if (props.staticData[field.field] == value) return
                                if (props.onChange) {
                                    const staticData = {...props.staticData};
                                    const subOrdinateFields = props.additionalFields.filter((f) => f.parentField === field.field);
                                    if (subOrdinateFields.length > 0) {
                                        subOrdinateFields.forEach((f) => {
                                            staticData[f.field] = '';
                                        })
                                    }
                                    props.onChange({...staticData, [field.field]: value})
                                }
                            }
                            }
                        />
                    //Largely used for dynamic fields that can be set external to the extension setup screen.
                    case 'defaultedOption':
                        if (field.searchable) {
                            return <React.Fragment key={field.field}>
                                <Label>{field.description}{getRequiredElement(field)}</Label>
                                <ExtensionFieldSearch
                                    field={field}
                                    initialValue={props.staticData[field.field] ?? ''}
                                    onChange={(value) => {
                                        if (props.staticData[field.field] == value) return
                                        if (props.onChange) {
                                            const staticData = {...props.staticData};
                                            const subOrdinateFields = props.additionalFields.filter((f) => f.parentField === field.field);
                                            if (subOrdinateFields.length > 0) {
                                                subOrdinateFields.forEach((f) => {
                                                    staticData[f.field] = '';
                                                })
                                            }
                                            props.onChange({...staticData, [field.field]: value})
                                        }
                                    }
                                    }
                                    extensionId={props.extensionId}
                                /></React.Fragment>
                        }

                        return <ExtensionDependentOption
                            key={field.field}
                            parentFieldValue={getParentFieldValue(field)}
                            initialValue={props.staticData[field.field] ?? ''}
                            optionsField={field}
                            onChange={(fieldKey, value) => {
                                if (props.staticData[fieldKey] == value) return
                                if (props.onChange) {
                                    const staticData = {...props.staticData};
                                    const subOrdinateFields = props.additionalFields.filter((f) => f.parentField === fieldKey);
                                    if (subOrdinateFields.length > 0) {
                                        subOrdinateFields.forEach((f) => {
                                            staticData[f.field] = '';
                                        })
                                    }
                                    props.onChange({...staticData, [field.field]: value})
                                }
                            }
                            }
                            extensionId={props.extensionId}
                            readOnly={!isDependentFieldAccessible(field)}
                            onLoading={(value) => {
                                if(value){
                                    setLoadingFields((a) => a.concat(field.field))
                                }
                                else{
                                    setLoadingFields((a) => a.filter(f => f!= field.field))
                                }
                            }}
                        />

                    case 'installOption':
                        return <ExtensionInstallOption
                            key={field.field}
                            initialValue={props.staticData[field.field] ?? ''}
                            optionsField={field}
                            onChange={(fieldKey, value) => {
                                if (props.staticData[fieldKey] == value) return
                                if (props.onChange) {
                                    const staticData = {...props.staticData};
                                    const subOrdinateFields = props.additionalFields.filter((f) => f.parentField === fieldKey);
                                    if (subOrdinateFields.length > 0) {
                                        subOrdinateFields.forEach((f) => {
                                            staticData[f.field] = '';
                                        })
                                    }
                                    props.onChange({...staticData, [field.field]: value})
                                }
                            }
                            }
                            extensionId={props.extensionId}
                        />
                    case 'flag':
                        return (
                            <div key={`extension-flag-${field.field}`} style={{paddingTop: "10px"}}>
                                <Checkbox checked={props.staticData[field.field] == 'true'}
                                          label={field.description}
                                          onChange={(fieldKey, value) => {
                                              if (props.staticData[field.field] == value?.toString()) return
                                              if (props.onChange) {
                                                  const staticData = {...props.staticData};
                                                  const subOrdinateFields = props.additionalFields.filter((f) => f.parentField === fieldKey);
                                                  if (subOrdinateFields.length > 0) {
                                                      subOrdinateFields.forEach((f) => {
                                                          staticData[f.field] = '';
                                                      })
                                                  }
                                                  props.onChange({
                                                      ...staticData,
                                                      [field.field]: value?.toString() ?? 'false'
                                                  })
                                              }
                                          }
                                          }
                                />
                            </div>
                        );
                    default:
                        return null;
                }
            })}</div>)})
} </div>)}

interface IExtensionStaticDataFieldProps {
    field: IAdditionalData;
    value: string;
    onChange: (value: string) => void;
    updateOnDisengage?: boolean;
}

const ExtensionStaticDataField: React.FC<IExtensionStaticDataFieldProps> = (props) => {
    const [currentValue, setCurrentValue] = React.useState<string>(props.value);
    useEffect(() => {
        if (currentValue !== props.value) {
            setCurrentValue(props.value)
        }
    }, [props.value]);

    const getRequiredElement = (field: IAdditionalData) => {
        if(!field.optional){
            return <div style={{display: "inline-flex", color: "rgb(164, 38, 44)", paddingLeft: "6px", paddingRight: "12px"}}>*</div>
        }
        return <></>
    }
    const getErrorMessage = (value: string): string => {
        return value.length < 1 && !props.field.optional ? 'Field is required' : '';
    };
    switch (props.field.dataType) {
        case 'boolean':
            return <Checkbox
                label={props.field.description}
                checked={currentValue === "true"}
                onChange={(e, d) => props.onChange(d ? "true" : "false")}
            />
        case 'catalog':
            return <ExtensionCatalogSelection
                label={props.field.description}
                catalog={currentValue}
                onChange={props.onChange}
            />
        case 'integer':
            return <div>
                <Label>{props.field.description}{getRequiredElement(props.field)}</Label>
                <SpinButton 
                    value={currentValue}
                    min={0}
                    step={1}
                    max={props.field.maxValue}
                    onChange={(e, d) => {
                        if(d) {
                            setCurrentValue(d)
                            if (!props.updateOnDisengage) props.onChange(d)
                        }
                    }}
                    onBlur={(e) => {
                        if (props.updateOnDisengage) {
                            props.onChange(e.target.value)
                        }
                    }
                    }
                />
                {!props.field.optional && !currentValue && <p className={'field-error-message'}>Field is required</p>}
            </div>
        case 'multiLineText': 
            return <TextField
                multiline={true}
                rows={4}
                onGetErrorMessage={getErrorMessage}
                placeholder={props.field.optional ? undefined : `Value required for ${props.field.description}`}
                label={props.field.description}
                value={currentValue}
                onChange={(e, d) => {
                    setCurrentValue(d ?? '')
                    if (!props.updateOnDisengage) props.onChange(d ?? '')
                }}
                onBlur={(e) => {
                    if (props.updateOnDisengage) {
                        props.onChange(e.target.value)
                    }
                }}
                required={!props.field.optional}
            />
        case 'password':
        case 'string':
        case 'text':
        default:
            return <DataField
                    maskFormat={props.field.maskFormat}
                    maskChar={props.field.maskChar}
                    mask={props.field.mask}
                    placeholder={props.field.optional ? undefined : `Value required for ${props.field.description}`}
                    label={props.field.description}
                    type={props.field.protected ? "password" : "text"}
                    onChange={(e, d) => {
                        setCurrentValue(d ?? '')
                        if (!props.updateOnDisengage) props.onChange(d ?? '')
                    }}
                    value={currentValue}
                    onBlur={(e) => {
                        if (props.updateOnDisengage) {
                            props.onChange(e.target.value)
                        }
                    }
                    }
                    required={!props.field.optional}
                />
    }
}

interface IExtensionCatalogSelection {
    label?: string;
    catalog?: string;
    onChange: (catalog: string) => void;
}

const ExtensionCatalogSelection: React.FC<IExtensionCatalogSelection> = (props) => {
    const getCatalogs = useGetCatalogsQuery();
    return (
        <Dropdown
            label={props.label ?? "Catalog"}
            options={getCatalogs.data?.map(c => ({
                key: c.name,
                text: c.name
            })) ?? []}
            disabled={getCatalogs.isFetching}
            placeholder={getCatalogs.isFetching ? 'Loading' : 'Select a catalog'}
            selectedKey={props.catalog}
            onChange={(e, d) => props.onChange(d?.key.toString() ?? '')}
        />
    );
}

interface IExtensionMappingFormProps {
    mappings: IImportMapping[];
    onChange: (mappings: IImportMapping[]) => void;
    onError: (message: string | undefined) => void;
    staticData: { [key: string]: string };
    id: string;
    installedId?: string;
}

const ExtensionMappingForm: React.FC<IExtensionMappingFormProps> = (props) => {
    const [getSample] = useLazyGetImportSampleQuery({pollingInterval: 0})
    const [loading, setLoading] = React.useState(false);
    const [sampleData, setSampleData] = React.useState<string[][]>([]);

    const run = async () => {
        setLoading(true);
        props.onError(undefined);
        const sample = await getSample({extensionId: props.id, request: {additionalFields: props.staticData, installedExtensionId: props.installedId}})
        if (sample.data?.columns) {
            setSampleData(sample.data.columns);
        } else {
            props.onError(sample.data?.error ?? 'An error occurred loading the sample data')
        }
        setLoading(false);
    }

    return <>
        <PrimaryButton
            onClick={run}
            text={!loading ? 'Load Sample' : 'Loading...'}
            disabled={loading}
        />
        <MappingTable
            transpose
            sampleData={sampleData}
            initialMapping={props.mappings}
            onChange={props.onChange}
        />
    </>
}

interface IExtensionFolioInstallButtonsProps {
    extensionFolio?: IInstalledExtensionFolio;
    onValidate: (response: IActionResponse) => void;
    onClose?: () => void;
}

export const ExtensionFolioInstallButtons: React.FC<IExtensionFolioInstallButtonsProps> = (props) => {
    const [validateInstalled] = useValidateExtensionFolioMutation();
    const [validating, setValidating] = React.useState(false);

    const validateExisting = async () => {
        if (props.extensionFolio?.id == null || props.extensionFolio?.commonInstalledFields == undefined) return;
        setValidating(true);
        if (props.extensionFolio.sourceExtensionFolio?.id) {
            const result = await validateInstalled({
                id: props.extensionFolio.sourceExtensionFolio.id,
                request: {
                    additionalData: props.extensionFolio.commonInstalledFields,
                    folioId: props.extensionFolio.id,
                    validationActions: props.extensionFolio.enabledSubExtensions
                }
            });
            if (IsMutationSuccess<IActionResponse>(result)) {
                props.onValidate(result.data);
            } else if (IsMutationError(result)) {
                props.onValidate({success: false, message: 'Failed to communicate with API', id: ''})
            }
        }
        setValidating(false);
    }

    return (
        <Stack horizontal tokens={{childrenGap: '1em', padding: '1em'}}>
            {props.extensionFolio?.hasValidation && <StackItem grow={1}>
                <DefaultButton
                    style={{width: '100%'}}
                    text={!validating ? 'Validate' : 'Validating...'}
                    onClick={validateExisting}
                    disabled={validating}
                />
            </StackItem>}
            {props.onClose &&
                <StackItem grow={1}>
                    <DefaultButton
                        onClick={props.onClose}
                        style={{width: '100%'}}
                        text={'Close'}
                    />
                </StackItem>
            }
        </Stack>
    )
}

interface IExtensionInstallButtonsProps {
    request?: IExtensionInstallRequest;
    installed?: string;
    extension?: IExtension;
    isNew?: boolean;
    onSaved: () => void;
    onValidate: (response: IActionResponse) => void;
    onSaveError: (error: string) => void;
    onClose: () => void;
}

export const ExtensionInstallButtons: React.FC<IExtensionInstallButtonsProps> = (props) => {
    const [validateExtension] = useValidateExtensionSettingsMutation();
    const [validateInstalled] = useValidateInstalledExtensionMutation();
    const [install] = useInstallExtensionMutation();
    const [update] = usePutInstalledExtensionMutation();
    const [validating, setValidating] = React.useState(false);

    const validateNew = async () => {
        if (props.extension?.id == null || props.request == null) return;
        setValidating(true);
        const result = await validateExtension({id: props.extension.id, request: props.request});
        if (IsMutationSuccess<IActionResponse>(result)) {
            props.onValidate(result.data);
        } else if (IsMutationError(result)) {
            props.onValidate({success: false, message: 'Failed to communicate with API', id: ''})
        }
        setValidating(false);
    }

    const validateExisting = async () => {
        if (props.installed == null || props.request == null) return;
        setValidating(true);
        const result = await validateInstalled({id: props.installed, request: props.request});
        if (IsMutationSuccess<IActionResponse>(result)) {
            props.onValidate(result.data);
        } else if (IsMutationError(result)) {
            props.onValidate({success: false, message: 'Failed to communicate with API', id: ''})
        }
        setValidating(false);
    }

    const save = async () => {
        if (props.request == null) {
            props.onSaveError('Request not found');
            return;
        }
        if (props.isNew) {
            if (props.extension?.id == null) {
                props.onSaveError('Extension ID not found');
                return;
            }
            const result = await install({request: props.request, extensionId: props.extension.id});
            if (IsMutationSuccess<IActionResponse>(result)) {
                if (result.data.success) {
                    props.onSaved();
                } else {
                    props.onSaveError(result.data.message);
                }
            } else {
                props.onSaveError('Failed to communicate with API');
            }
        } else {
            if (props.installed == null) {
                props.onSaveError('Installed extension ID not found');
                return;
            }
            const result = await update({extensionId: props.installed, request: props.request});
            if (IsMutationSuccess<IActionResponse>(result)) {
                if (result.data.success) {
                    props.onSaved();
                } else {
                    props.onSaveError(result.data.message);
                }
            } else {
                props.onSaveError('Failed to communicate with API');
            }
        }
    }

    return (
        <Stack horizontal tokens={{childrenGap: '1em', padding: '1em'}}>
            <StackItem grow={1}>
                <PrimaryButton
                    style={{width: '100%'}}
                    text={'Save'}
                    onClick={save}
                />
            </StackItem>
            {props.extension?.hasValidation && <StackItem grow={1}>
                <DefaultButton
                    style={{width: '100%'}}
                    text={!validating ? 'Validate' : 'Validating...'}
                    onClick={props.isNew ? validateNew : validateExisting}
                    disabled={validating}
                />
            </StackItem>}
            <StackItem grow={1}>
                <DefaultButton
                    onClick={props.onClose}
                    style={{width: '100%'}}
                    text={'Close'}
                />
            </StackItem>
        </Stack>
    )
}

export default ExtensionInstallForm;