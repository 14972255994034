import {IAdditionalData, IExtensionOption} from "../../models/Extension";
import React, {useEffect, useState} from "react";
import {ComboBox} from "@fluentui/react";
import {useLazyGetExportExtensionOptionsQuery} from "../../store/Api";

interface IExtensionDependentOptionProps {
    initialValue: string;
    optionsField: IAdditionalData;
    parentFieldValue?: string;
    extensionId: string;
    onChange: (field: string, value: string) => void;
    onLoading?: (isLoading: boolean) => void;
    readOnly?: boolean;
}
const ExtensionDependentOption: React.FC<IExtensionDependentOptionProps> = (props) => {
    const [selectedOption, setSelectedOption] = useState<string>()
    const [options, setOptions] = useState<IExtensionOption[]>([])
    const [additionalData, setAdditionalData] = useState<{[p:string]: string} | undefined>(undefined)
    const [loadingOptions, setLoadingOptions] = useState<boolean>()
    const [optionApi] = useLazyGetExportExtensionOptionsQuery();
    
    useEffect(() => {
        if(props.optionsField.parentField && props.parentFieldValue){
            setAdditionalData({[props.optionsField.parentField]: props.parentFieldValue})
        }
    }, [props.parentFieldValue]);

    useEffect(() => {
        if(props.optionsField.field && props.optionsField.field != '' && props.extensionId) {
            setLoadingOptions(true)
            props.onLoading?.(true)
            optionApi({
                extensionId: props.extensionId,
                field: props.optionsField.field,
                additionalData: additionalData
            }).then((res) => {
                setOptions(res.data?.options ?? [])
                setLoadingOptions(false)

                props.onLoading?.(false)

            })
        }
    }, [additionalData, props.extensionId, props.optionsField.field])
    useEffect(() => {
        if(!selectedOption && options){
            if(props.initialValue){
                setSelectedOption(props.initialValue)
                props.onChange(props.optionsField.field, props.initialValue)
            }
            else {
                const defaultOption = options?.filter((a) => a.default)
                if (defaultOption && defaultOption.length > 0) {
                    setSelectedOption(defaultOption[0].value)
                    props.onChange(props.optionsField.field, defaultOption[0].value)
                }
            }
        }
    }, [options])

    return(<ComboBox
        label={props.optionsField.description}
        options={options?.map(c => ({
            key: c.value,
            text: c.display
        })) ?? []}
        errorMessage={!props.optionsField.optional && !selectedOption? "Field is required" : ""}
        autoComplete={"on"}
        disabled={!options || loadingOptions || props.readOnly}
        required={!props.optionsField.optional}
        placeholder={props.readOnly? `Select value for ${props.optionsField.parentField} to enable` : (loadingOptions ? "Loading Options..." : "Select Option")}
        selectedKey={selectedOption}
        onChange={(e, d) => {
            setSelectedOption(d?.key?.toString() ?? "")
            props.onChange(props.optionsField.field, d?.key.toString() ?? '')
        }}/>);
}
export default ExtensionDependentOption;