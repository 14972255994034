import React from "react";
import {IVendor} from "../../models/Search";
import CurrencyFormat from "react-currency-format";
import {InStockDisplay} from "../Products/InStockDisplay";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {DetailsList, Icon, SelectionMode, Spinner, TooltipHost, IconButton} from "@fluentui/react";
import { useGetAllVendorSettingsQuery } from "../../store/Api";
import { renderVendorNameFromAllSettings } from "../../logic/Product";

interface IVendorsTableProps {
    vendors: IVendor[];
    refreshing?: boolean;
    product: INormalizedProduct;
    editable?: boolean;
}

export const VendorsTable: React.FC<IVendorsTableProps> = (props) => {
    const vendorSettings = useGetAllVendorSettingsQuery();
    const columns = [
        ColumnGenerator('Vendor', 
            'vendorName', 
            150, 
            150,
            (vendor: IVendor) => {
                if (vendor.subVendorName && vendor.subVendorName !== "") return <>{renderVendorNameFromAllSettings(vendor.vendorName, vendorSettings.data)} - {vendor.subVendorName}</>
                return <>{renderVendorNameFromAllSettings(vendor.vendorName, vendorSettings.data)}</>
            }
            ),
        ColumnGenerator('Name', 'name', 200, undefined, undefined, true),
        ColumnGenerator('VPN', 'vendorPartNumber', 100, 100),
        ColumnGenerator('Price',
            'price',
            100,
            100,
            (vendor: IVendor) =>
                vendor.cost ? <CurrencyFormat
                        value={vendor.cost}
                        displayType="text"
                        thousandSeparator=","
                        prefix="$"
                        fixedDecimalScale={true}
                    />
                    : <></>
        ),
    ]

    if (props.product?.vendors?.find(v => v.startQuantity || v.endQuantity)) {
        columns.push(ColumnGenerator('Start Quantity', 'startQuantity', 100, 100));
        columns.push(ColumnGenerator('End Quantity', 'endQuantity', 100, 100));
    }
    columns.push(
        ColumnGenerator('Status', 'status', 50, 50, (vendor: IVendor) => {
                if (props.refreshing) return <Spinner />
                return (vendor.status != null)
                    ? <div style={{textAlign: 'center'}}>
                        <TooltipHost content={vendor.status}>
                            <Icon iconName={vendor.status.toLowerCase().indexOf("authorized") !== -1 ? 'Warning' : 'Info'}/>
                        </TooltipHost>
                    </div>
                    : <></>;
            }
        )
    )
    columns.push(
        ColumnGenerator(
            'Availability',
            'inStock',
            200,
            200,
            (vendor: IVendor) => <>
                <InStockDisplay
                    vendor={vendor}
                    product={props.product}
                    loadingWarehouses={!!props.refreshing}
                />
            </>
        )
    )
    if (props.product.vendors?.find(v =>v.url !== null || "")){
         columns.push(
           ColumnGenerator(
               'Link',
               'url',
               50,
               50,
               (vendor: IVendor) => {
                   return (vendor.url !== null) ?
                           <IconButton
                               href={`${vendor.url}`}
                               iconProps={{iconName: 'Link'}}
                               target={'_blank'}
                           />
                       : <></>;    
               }
           )
         )    
    }
   
    return <DetailsList items={props.product?.vendors ?? []} selectionMode={SelectionMode.none} columns={columns}/>
};
