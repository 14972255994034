import React from 'react';
import { IStorefrontCustomTheme } from '../../../models/Storefront';
import { CommandBar, IColumn, ICommandBarItemProps, SelectionMode, ShimmeredDetailsList, Icon, Dialog, DialogType, DefaultButton, DialogFooter } from '@fluentui/react';
import { useDeleteStorefrontCustomThemeMutation, useGetStorefrontCustomThemesQuery } from '../../../store/Api';
import { ColumnGenerator, ButtonColumnGenerator } from '../../../logic/Columns/ColumnGenerator';

interface IStorefrontThemesTable {
    onDetailsClick: (theme: IStorefrontCustomTheme) => void;
    onNewClick: () => void;
}

const StorefrontThemesTable: React.FC<IStorefrontThemesTable> = (props) => {
    const themes = useGetStorefrontCustomThemesQuery();
    const [deleteTheme] = useDeleteStorefrontCustomThemeMutation();
    const [deleteDialog, setDeleteDialog] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        ColumnGenerator('Name', 'name', 100),
        ColumnGenerator('Custom CSS', 'customCSS', 100, 100, (item: IStorefrontCustomTheme) =>
            <>{item.customCSS && <Icon iconName='CheckMark' />}</>
        ),
        ButtonColumnGenerator((item: IStorefrontCustomTheme) => setDeleteDialog(item.id), 'Delete', 'Delete', false),
        ButtonColumnGenerator((item: IStorefrontCustomTheme) => props.onDetailsClick(item), 'Details'),
    ]

    const commands: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: 'New',
            iconProps: { iconName: 'Add' },
            onClick: props.onNewClick
        }
    ];

    const farCommands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            iconOnly: true,
            onClick: () => { themes.refetch() }
        }
    ]
    return (
        <div>
            <CommandBar items={commands} farItems={farCommands} />
            <ShimmeredDetailsList
                enableShimmer={themes.isLoading}
                items={themes.data?.items ?? []}
                columns={columns}
                selectionMode={SelectionMode.none}
                />
            <Dialog
                hidden={!deleteDialog}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Delete Theme',
                    subText: `Are you sure you want to delete ${(themes.data?.items.find(t => t.id === deleteDialog))?.name}?`,
                }}
                modalProps={{
                    isBlocking: true,
                }}
            >
                <DialogFooter>
                    <DefaultButton
                        primary
                        text='Yes'
                        onClick={() => {
                            if (deleteDialog) {
                                deleteTheme(deleteDialog)
                                    .then(() => {
                                        setDeleteDialog(undefined);
                                        themes.refetch();
                                    });
                            }
                        }}
                    />
                    <DefaultButton
                        text='No'
                        onClick={() => {
                            setDeleteDialog(undefined);
                        }}
                    />
                </DialogFooter>
            </Dialog>
        </div>
    );
}

export default StorefrontThemesTable;