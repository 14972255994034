import React from 'react';
import { IStorefrontCustomTheme } from '../../../models/Storefront';
import StorefrontThemesTable from './StorefrontThemesTable';
import StorefrontThemePanel from './StorefrontThemePanel';

const StorefrontThemeManagement: React.FC = () => {
    const [selected, setSelected] = React.useState<IStorefrontCustomTheme | undefined>();
    const [showPanel, setShowPanel] = React.useState(false);

    const onDetailsClick = (theme: IStorefrontCustomTheme) => {
        setSelected(theme);
        setShowPanel(true);
    }

    const onNewClick = () => {
        setSelected(undefined);
        setShowPanel(true);
    }

    return (
        <div>
            <StorefrontThemesTable onDetailsClick={onDetailsClick} onNewClick={onNewClick} />
            <StorefrontThemePanel isOpen={showPanel} onDismiss={() => setShowPanel(false)} theme={selected} />
        </div>
    );
}

export default StorefrontThemeManagement;