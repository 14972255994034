import React from 'react';
import { IVendorSettings } from '../../models/Settings';
import { Checkbox, DefaultButton, MessageBar, MessageBarType, Panel, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { usePutVendorSettingsMutation } from '../../store/Api';
import { IsMutationSuccess } from '../../logic/MutationTypeChecker';
import { IActionResponse } from '../../dal/BaseDAL';

interface IVendorManagementEditPanelProps {
    vendor?: IVendorSettings;
    isOpen: boolean;
    onClose: () => void;
}

const VendorManagementEditPanel: React.FC<IVendorManagementEditPanelProps> = (props) => {
    const [vendor, setVendor] = React.useState<IVendorSettings>(props.vendor ?? {sourceVendor: '', enabled: true});
    const [saveVendor] = usePutVendorSettingsMutation();
    const [error, setError] = React.useState<string | undefined>();

    React.useEffect(() => {
        setVendor(props.vendor ?? {sourceVendor: '', enabled: true});
    }, [props.vendor]);

    const save = async () => {
        if (!vendor) return;
        const result = await saveVendor({vendor});
        if (IsMutationSuccess<IActionResponse>(result)) {
            if (result.data.success) {
                props.onClose();
            } else {
                setError(result.data.message ?? 'An error occurred');
            }
        } else {
            setError('An unknown error occurred');
        }
    }

    const renderFooter = () => (<Stack horizontal tokens={{childrenGap: '1em'}}>
        <PrimaryButton onClick={save}>Save</PrimaryButton>
        <DefaultButton onClick={props.onClose}>Cancel</DefaultButton>
        </Stack>);

    const renderHeader = () => (<Stack tokens={{childrenGap: '1em'}} grow style={{paddingLeft: '25px'}}>
        <h1>{props.vendor ? 'Edit Vendor' : 'Add Vendor'}</h1>
        {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
        </Stack>);

    return (
        <Panel
            isOpen={props.isOpen}
            onDismiss={props.onClose}
            closeButtonAriaLabel='Close'
            onRenderFooterContent={renderFooter}
            onRenderHeader={renderHeader}
            headerText={props.vendor ? 'Edit Vendor' : 'Add Vendor'}
            isFooterAtBottom={true}
        >
            <Stack tokens={{childrenGap: '1em'}}>
                <TextField label='Vendor Name' value={vendor.sourceVendor} onChange={(e, v) => setVendor({...vendor, sourceVendor: v ?? ''})} />
                <TextField label='Display Name' value={vendor.vendorDisplayName} onChange={(e, v) => setVendor({...vendor, vendorDisplayName: v})} />
                <Checkbox label='Enabled' checked={vendor.enabled} onChange={(e, c) => setVendor({...vendor, enabled: c})} />
                <TextField label='Punchout Name' value={vendor.punchoutVendorName} onChange={(e, v) => setVendor({...vendor, punchoutVendorName: v})} />
            </Stack>
        </Panel>
    );
}

export default VendorManagementEditPanel;