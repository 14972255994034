import React from 'react';
import { useGetAllVendorSettingsQuery, useUpdateOrganizationMutation } from '../../store/Api';
import { IVendorSettings } from '../../models/Settings';
import { CommandBar, Dropdown, IColumn, ICommandBarItemProps, Icon, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import { ButtonColumnGenerator, ColumnGenerator } from '../../logic/Columns/ColumnGenerator';
import VendorManagementEditPanel from '../../components/Vendors/VendorManagementEditPanel';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

const VendorManagementPage: React.FC = () => {
    const vendors = useGetAllVendorSettingsQuery();
    const [selectedVendor, setSelectedVendor] = React.useState<IVendorSettings | undefined>();
    const [modalOpen, setModalOpen] = React.useState(false);
    const selectedOrg = useSelector((state: RootState) => state.settings.selectedOrganization);
    const [excludeFromBestPrice, setExcludeFromBestPrice] = React.useState<string[]>([]);
    const [savingOrg, setSavingOrg] = React.useState(false);
    const [updateOrg] = useUpdateOrganizationMutation();

    React.useEffect(() => {
        if (selectedOrg) {
            setExcludeFromBestPrice(selectedOrg.excludeFromBestPrice ?? []);
        }
    }, [selectedOrg]);

    const commands: ICommandBarItemProps[] = [
        {
            key: 'Add',
            text: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: () => {
                setSelectedVendor(undefined);
                setModalOpen(true);
            }
        }
    ]

    const farComands: ICommandBarItemProps[] = [
        {
            key: 'Refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            iconOnly: true,
            onClick: () => {vendors.refetch();}
        }
    ]

    const selectVendor = (v: IVendorSettings) => {
        setSelectedVendor(v);
        setModalOpen(true);
    }

    const columns: IColumn[] = [
        ColumnGenerator('Vendor Name', 'sourceVendor', 100),
        ColumnGenerator('Display Name', 'vendorDisplayName', 200, 200),
        ColumnGenerator('Punchout Name', 'punchoutVendorName', 200, 200),
        ColumnGenerator('Enabled', 'enabled', 100, 100, (v: IVendorSettings) => v.enabled ? <Icon iconName='CheckMark' /> : <></>),
        ButtonColumnGenerator(selectVendor, 'Edit', 'Edit')
    ]

    const saveOrg = async () => {
        setSavingOrg(true);
        if (selectedOrg && (
            selectedOrg.excludeFromBestPrice !== excludeFromBestPrice
        )) {
            await updateOrg({
                excludeFromBestPrice,
            });
        }
        setSavingOrg(false);
    }

    return (
        <div className='main-content'>
            <h1>Vendor Management</h1>
            <CommandBar items={commands} farItems={farComands} />
            <ShimmeredDetailsList items={vendors.data?.items ?? []} enableShimmer={vendors.isFetching} columns={columns} selectionMode={SelectionMode.none} />
            <VendorManagementEditPanel vendor={selectedVendor} isOpen={modalOpen} onClose={() => setModalOpen(false)} />
            <h3>Best Price Column</h3>
            <Dropdown
                label='Exclude from Best Price'
                multiSelect
                selectedKeys={excludeFromBestPrice}
                options={vendors.data?.items?.map(v => ({key: v.sourceVendor, text: v.vendorDisplayName ?? v.sourceVendor})) ?? []}
                disabled={savingOrg || vendors.isLoading}
                onChange={(e,item) => {
                    if (!item) return;
                    setExcludeFromBestPrice(
                        item.selected ? [...excludeFromBestPrice, item.key as string] : excludeFromBestPrice.filter(key => key !== item.key),
                    );
                }}
                onBlur={saveOrg}
                />
        </div>
    );
}

export default VendorManagementPage;