import React, {useEffect, useState} from 'react';
import { IGlobalStorefrontSettings, PAYMENT_PROCESSOR_KEYS } from '../../models/Storefront';
import {IDropdownOption, TextField} from '@fluentui/react';
import FontPicker from '../Inputs/FontPicker';
import CompactColorPicker from '../Inputs/CompactColorPicker';
import NumberField from '../Inputs/NumberField';
import MarkupTypeSelection from './MarkupTypeSelection';
import CurrencySelection from '../CurrencySelection/CurrencySelection';
import StorefrontThemeSelection from './StorefrontThemeSelection';
import StorefrontDropdown from './StorefrontDropdown';
import PaymentProcessorDropdown from './PaymentProcessorDropdown';
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {useGetOrganizationsQuery, useGetVendorsQuery} from "../../store/Api";
import BooleanDropdown from "./BooleanDropdown";
import DataField from "../Common/DataField";

interface IGlobalStorefrontSettingsFormProps {
    globalSettings: IGlobalStorefrontSettings;
    onChange: (settings: IGlobalStorefrontSettings) => void;
}

const GlobalStorefrontSettingsForm: React.FC<IGlobalStorefrontSettingsFormProps> = ({globalSettings, onChange}) => {
    const [defaultCatalog, setDefaultCatalog] = useState<string>();
    const organizations = useGetOrganizationsQuery();
    useEffect(() => {
        if(organizations.data) {
            const defaultOrganization = organizations.data.find(o => o.defaultCatalog);
            if(defaultOrganization) {
                setDefaultCatalog(defaultOrganization.defaultCatalog);
            }
        }
    },[organizations.data]);

    const vendors = useGetVendorsQuery(defaultCatalog ?? "", {skip: !defaultCatalog})
    
    const onChangeVendors = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item && globalSettings.storefrontVendors) {
            onChange({...globalSettings, storefrontVendors: item.selected? [...globalSettings.storefrontVendors, item.key as string] : globalSettings.storefrontVendors.filter(key => key!== item.key) });
        }
    };
    const filterStrategyOptions = () => {
        return [
            {key: 'exclude', text: 'Exclude'},
            {key: 'include', text: 'Include'},
        ]
    }
    return <div style={{ width: '100%', minWidth: '300px'}}>
        <h2>Global Settings</h2>
        <StorefrontDropdown label='Default Storefront Sub URL' selected={globalSettings.defaultStorefrontSubUrl} onChange={(v) => onChange({...globalSettings, defaultStorefrontSubUrl: v})} defaultLabel={'NOT SET'} />
        <TextField label='Name' value={globalSettings.name} onChange={(e, v) => onChange({...globalSettings, name: v})} />
        <TextField label='Title' value={globalSettings.title} onChange={(e, v) => onChange({...globalSettings, title: v})} />
        <TextField label='Banner Text' value={globalSettings.bannerText} onChange={(e, v) => onChange({...globalSettings, bannerText: v})} />
        <h3>Styles</h3>
        <StorefrontThemeSelection theme={globalSettings.theme} onChange={(v) => onChange({...globalSettings, theme: v})} />
        {globalSettings.theme === 'CUSTOM' && <>
            <CompactColorPicker label='Menu Bar Color' color={globalSettings.menuBarColor ?? '#fff'} onChange={(v) => onChange({...globalSettings, menuBarColor: v})} />
            <CompactColorPicker label='Menu Bar Text Color' color={globalSettings.menuBarTextColor ?? '#000'} onChange={(v) => onChange({...globalSettings, menuBarTextColor: v})} />
            <CompactColorPicker label='Background Color' color={globalSettings.backgroundColor ?? '#fff'} onChange={(v) => onChange({...globalSettings, backgroundColor: v})} />
            <CompactColorPicker label='Text Color' color={globalSettings.textColor ?? '#000'} onChange={(v) => onChange({...globalSettings, textColor: v})} />
        </>}
        <FontPicker label='Header Font' selectedFont={globalSettings.headerFont} onChange={(v) => onChange({...globalSettings, headerFont: v})} />
        <FontPicker label='Default Font' selectedFont={globalSettings.defaultFont} onChange={(v) => onChange({...globalSettings, defaultFont: v})} />
        <h3>Order Settings</h3>
        <BooleanDropdown value={globalSettings.requireNotes ?? false} onChange={(v) => onChange({...globalSettings, requireNotes: v})} label={'Use Notes'} />
        <DataField
            toolTip={'When enabled, Notes provides a dynamic input for the customer to provide additional information about their order. Use this field to provide a label for the Notes input field which will appear when the customer goes to check out from storefront.'}
            label='Notes Label' 
            value={globalSettings.notesLabel} 
            onChange={(e, v) => onChange({...globalSettings, notesLabel: v})} placeholder={'Additional Order Information'} 
        />
        <DataField 
            multiline
            toolTip={'Use Notes Instructions to provide detailed instructions about how to fill out the Notes field. Markdown is supported.'}
            label={'Notes Instructions'} 
            value={globalSettings.notesInstructions} 
            onChange={(e, v) => onChange({...globalSettings, notesInstructions: v})} />

        <DataField 
            multiline 
            label={'Terms and Conditions'} 
            toolTip={globalSettings.paymentProcessor && globalSettings.paymentProcessor != PAYMENT_PROCESSOR_KEYS.NONE ? '' : 'Markdown is supported'} 
            value={globalSettings.termsAndConditions} 
            onChange={(e, v) => {
                onChange({...globalSettings, termsAndConditions: v})
            }}
        />
        <TextField type='email' label='Order Email' value={globalSettings.orderEmail} onChange={(e, v) => onChange({...globalSettings, orderEmail: v})} />
        <TextField multiline label='Customer Email Text' value={globalSettings.customerEmailText} onChange={(e, v) => onChange({...globalSettings, customerEmailText: v})} />
        <TextField label='Customer Email Subject' value={globalSettings.customerEmailSubject} onChange={(e, v) => onChange({...globalSettings, customerEmailSubject: v})} />
        <h3>Markup Settings</h3>
        <NumberField label='Default Markup' value={globalSettings.defaultMarkup} onChange={(v) => onChange({...globalSettings, defaultMarkup: v})} />
        <MarkupTypeSelection value={globalSettings.defaultMarkupType} onChange={(v) => onChange({...globalSettings, defaultMarkupType: v})} label='Default Markup Type' />
        <h3>Payment Settings</h3>
        <CurrencySelection value={globalSettings.currency} onChange={(v) => onChange({...globalSettings, currency: v})} />
        <PaymentProcessorDropdown value={globalSettings.paymentProcessor ?? PAYMENT_PROCESSOR_KEYS.NONE} onChange={(v) => onChange({...globalSettings, paymentProcessor: v})} />
        <BooleanDropdown value={globalSettings.collectTax ?? false} onChange={(v) => onChange({...globalSettings, collectTax: v})} label={"Collect Tax"} />
        <h3>Other Settings</h3>
        <TextField type='email' label='Order Failure Email' value={globalSettings.orderFailureEmail} onChange={(e,v) => onChange({...globalSettings, orderFailureEmail: v})} />
        <Dropdown
            placeholder={"Select Vendors"}
            multiSelect={true}
            label={"Storefront Vendor Filter"}
            selectedKeys={globalSettings.storefrontVendors}
            options={vendors?.data?.items?.map(vendor => ({ key: vendor, text: vendor })) ?? []}
            onChange={onChangeVendors}
            disabled={vendors.isLoading || vendors.isFetching}
        />
        <Dropdown
            placeholder={"Select Filter Strategy"}
            multiSelect={false}
            label={"Vendor Filter Strategy"}
            selectedKey={globalSettings.vendorFilterStrategy ?? "include"}
            options={filterStrategyOptions()}
            onChange={(e, v) => {onChange({...globalSettings, vendorFilterStrategy: v?.key.toString() })}}
        />
    </div>
}

export default GlobalStorefrontSettingsForm;