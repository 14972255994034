import React, {useEffect} from 'react'
import {
    DetailsList,
    IColumn,
    IColumnDragDropDetails,
} from '@fluentui/react/lib/DetailsList';
import {
    Modal,
    Stack,
    IStackTokens,
    Spinner,
    DropdownMenuItemType,
    IconButton,
    Label,
    CommandBar, ICommandBarItemProps
} from "@fluentui/react";
import {DefaultButton, PrimaryButton} from '@fluentui/react/lib/Button';
import {Dropdown, IDropdownStyles, IDropdownOption} from '@fluentui/react/lib/Dropdown';
import {COLUMN_PROPERTIES, renderSearchColumns, renderWorkspaceColumns} from '../../logic/Columns/ProductColumns';
import {IWorkspaceItem} from '../../models/Workspace';
import {
    useGetAllVendorSettingsQuery,
    useGetColumnsQuery,
    useGetMetadataQuery,
    useSetSearchColumnsMutation,
    useSetWorkspaceColumnsMutation
} from "../../store/Api";
import {MODAL_ICON_BUTTON_STYLE, MODAL_STYLE} from "../../Constants";
import _ from 'lodash'
import { INormalizedProduct } from '../../models/NormalizedProduct';
import { renderVendorNameFromAllSettings } from '../../logic/Product';

const WORKSPACE_OPTIONS: IDropdownOption[] = [
    {key: 'Standard Fields', text: 'Standard Fields', itemType: DropdownMenuItemType.Header},
    {key: 'image', text: 'Image'},
    {key: 'name', text: 'Name'},
    {key: 'availability', text: 'Availability'},
    {key: 'cost', text: 'Cost'},
    {key: 'quantity', text: 'Quantity'},
    {key: 'actions', text: 'Actions'},
    {key: 'price', text: 'Price'},
    {key: 'source', text: 'Source'},
    {key: 'markup', text: 'Markup %'},
    {key: 'taxRate', text: 'Tax Code'},
    {key: 'isTaxable', text: 'Taxable'},
    {key: 'priceLevel', text: 'Price Level'},
    {key: 'internalPartNumber', text: 'Internal Part Number'},
    {key: 'msrp', text: 'MSRP'},
];

const SEARCH_OPTIONS: IDropdownOption[] = [
    {key: 'Standard Fields', text: 'Standard Fields', itemType: DropdownMenuItemType.Header},
    {key: 'favorite', text: 'Favorite'},
    {key: 'image', text: 'Image'},
    {key: 'manufacturer', text: 'Manufacturer'},
    {key: 'mfp', text: 'MFP'},
    {key: 'name', text: 'Name'},
    {key: 'availability', text: 'Availability'},
    {key: 'cost', text: 'Cost'},
    {key: 'details', text: 'Details'},
    {key: 'addWs', text: 'Add to Workspace'},
    {key: 'internalPartNumber', text: 'Internal Part Number'},
    {key: 'best_price', text: 'Best Price'},
    {key: 'msrp', text: 'MSRP'},
];

const EXAMPLE_WORKSPACE_ITEM: IWorkspaceItem = {
    id: "example",
    productId: "example",
    catalog: "example Catalog",
    workspace: "example workspace",
    details: {price: 100, quantity: 5},
    product: {
        id: "example",
        description: "This is a description",
        manufacturer: "AdaptiveCatalog",
        manufacturerPartNumber: "exampleMFP",
        msrp: 23,
        name: "Example Product",
        vendors: [],
        attributes: [],
        facets: [],
        filters: [],
        image: "mainLogo.png",
        images: [],
        catalog: "technology",
        links: [],
        favorite: {user: false, company: false},
        priceLevels: [
            {
                partNumber: '1234',
                startQuantity: 1,
                endQuantity: 15,
            },
            {
                partNumber: 'qwerty',
                startQuantity: 16
            }
        ]
    }
};

const EXAMPLE_SEARCH_ITEM: INormalizedProduct = {
    id: "example",
    description: "This is a description",
    manufacturer: "AdaptiveCatalog",
    manufacturerPartNumber: "exampleMFP",
    msrp: 23,
    name: "Example Product",
    bestBasePrice: 100,
    bestPrice: 90,
    bestVendor: 'Example Vendor',
    bestVendorOnHand: 383,
    attributes: [],
    facets: [],
    filters: [],
    image: "mainLogo.png",
    images: [],
    catalog: "technology",
    links: [],
    favorite: {user: false, company: false},
    vendors: [{
        cost: 100,
        onHand: 383,
        vendorPartNumber: '1234',
        priceBreaks: [
            {
                quantity: 1,
                price: 100
            },
            {
                quantity: 5,
                price: 90
            }
        ],
        inStock: true,
        vendorName: 'Example Vendor'
    }],
};

const DEFAULT_SEARCH_COLUMNS = ['favorite', 'image', 'manufacturer', 'mfp', 'name', 'availability', 'cost', 'details'];
const DEFAULT_WORKSPACE_COLUMNS = ['image', 'name', 'availability', 'cost', 'quantity', 'actions'];

interface ICustomizationModalProps {
    page: 'search' | 'workspace';
    isOpen: boolean;
    onClose: () => void;
}

const CustomizationModal: React.FC<ICustomizationModalProps> = (props) => {
    const getColumns = useGetColumnsQuery();
    const vendorSettings = useGetAllVendorSettingsQuery();
    const metadata = useGetMetadataQuery(undefined);

    const [setSearchColumns] = useSetSearchColumnsMutation();
    const [setWorkspaceColumns] = useSetWorkspaceColumnsMutation();

    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

    const searchItems = [EXAMPLE_SEARCH_ITEM];
    const wsItems = [EXAMPLE_WORKSPACE_ITEM];
    const [testColumns, setTestColumns] = React.useState<IColumn[]>([]);
    const items = props.page === "search" ? searchItems : wsItems;
    const [searchOptions, setSearchOptions] = React.useState<IDropdownOption[]>([])

    useEffect(() => {
        if (getColumns.data) {
            if (props.page === 'search') {
                setSelectedKeys(getColumns.data.searchColumns);
            } else if (props.page === 'workspace') {
                setSelectedKeys(getColumns.data.workspaceColumns);
            }
        } else if (!getColumns.isLoading) { // If there are no columns, set the default columns
            setSelectedKeys(props.page === 'search'
                ? DEFAULT_SEARCH_COLUMNS
                : DEFAULT_WORKSPACE_COLUMNS
            );
        }
    }, [getColumns.data, props.page, props.isOpen])

    useEffect(() => {
        if (metadata.data?.vendors && props.page === 'search') {
            const options = _.cloneDeep(SEARCH_OPTIONS);
            options.push({key: 'Vendor Fields', text: 'Vendor Fields', itemType: DropdownMenuItemType.Header})
            for (const vendor of metadata.data.vendors) {
                const vendorName = renderVendorNameFromAllSettings(vendor, vendorSettings.data);
                options.push({key: `VENDOR:${vendor}`, text: `Vendor: ${vendorName}`});
                options.push({key: `DENSE_VENDOR:${vendor}`, text: `Vendor: ${vendorName} (Dense)`});
            }
            setSearchOptions(options);
        }
    }, [metadata.data, props.page]);

    useEffect(() => {
        setTestColumns(props.page === "search"
            ? renderSearchColumns(selectedKeys, false, true)
            : renderWorkspaceColumns(selectedKeys,
                false,
                true,
                () => null,
                () => null,
                () => null,
                () => null
            )
        );

    }, [selectedKeys])

    const onColumnDrop = (details: IColumnDragDropDetails) => {
        const draggedItems: IColumn = testColumns[details.draggedIndex];
        const newColumns: IColumn[] = [...testColumns];
        newColumns.splice(details.draggedIndex, 1);
        newColumns.splice(details.targetIndex, 0, draggedItems);
        setTestColumns(newColumns);
        setSelectedKeys(newColumns.map(newColumn => newColumn.key));
    }
    
    const getDefaultColumns = () => {
        const columns = []
        if(props.page === 'search'){
            return DEFAULT_SEARCH_COLUMNS.map(a => COLUMN_PROPERTIES[a].basicColumn)
        }
        return DEFAULT_WORKSPACE_COLUMNS.map(a => COLUMN_PROPERTIES[a].basicColumn)
    }
    const onResetColumns = () => {
        setTestColumns(getDefaultColumns())
        setSelectedKeys(props.page === 'search'
            ? DEFAULT_SEARCH_COLUMNS
            : DEFAULT_WORKSPACE_COLUMNS
        );
    }

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: {width: 300,},
    };

    const onChange = (item: IDropdownOption | undefined): void => {
        if (item) {
            setSelectedKeys(
                item.selected
                    ? [...selectedKeys, item.key as string]
                    : selectedKeys.filter(key => key !== item.key)
            );
        } else {
            setSelectedKeys(props.page === 'search'
                ? DEFAULT_SEARCH_COLUMNS
                : DEFAULT_WORKSPACE_COLUMNS
            );
        }
    }

    function saveAndClose() {
        if (props.page === 'search') {
            setSearchColumns(testColumns);
        } else if (props.page === 'workspace') {
            setWorkspaceColumns(testColumns);
        }
        close();
    }

    function close() {
        onChange(undefined);
        props.onClose();
    }
    const commands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Reset Columns',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {onResetColumns();}
        }
    ];

    const stackTokens: IStackTokens = {childrenGap: 10};
    return (
        <Modal
            isOpen={props.isOpen}
            onDismiss={() => close()}
            containerClassName={MODAL_STYLE.container}
            styles={{
                main: {
                    width: '80%',
                    minWidth: '40em',
                }
            }}
        >

            <div className={MODAL_STYLE.header}>
                <span>Customize {props.page === 'search' ? 'Search' : 'Workspace'} Columns</span>
                <IconButton
                    styles={MODAL_ICON_BUTTON_STYLE}
                    iconProps={{iconName: 'Cancel'}}
                    ariaLabel="Close popup modal"
                    onClick={() => props.onClose()}
                />
            </div>
            {getColumns.isLoading &&
                <div className={MODAL_STYLE.body}>
                    <Spinner label="Loading..." ariaLive="assertive" style={{margin: '5em'}}/>
                </div>
            }

            {!getColumns.isLoading && 
                <div className={MODAL_STYLE.body}>
                    <div>
                        <CommandBar items={commands} />
                        <Dropdown
                            placeholder="Select options"
                            label="Select Columns"
                            selectedKeys={selectedKeys}
                            multiSelect
                            options={props.page === "search" ? searchOptions : WORKSPACE_OPTIONS}
                            styles={dropdownStyles}
                            onChange={(_e, selectedItem) => onChange(selectedItem)}
                        />
                        <Label style={{marginTop: '1em'}}>Arrange Columns</Label>
                        <DetailsList
                            setKey="items"
                            items={items}
                            columns={testColumns}
                            columnReorderOptions={{
                                frozenColumnCountFromStart: 0,
                                frozenColumnCountFromEnd: 0,
                                onColumnDrop
                            }}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                        />
                    </div>
                    <div className={MODAL_STYLE.body}>
                        <Stack horizontal tokens={stackTokens} style={{paddingTop: '10px'}} horizontalAlign='end'>
                            <PrimaryButton text="Save" onClick={() => saveAndClose()}/>
                            <DefaultButton text="Close" onClick={() => close()}/>
                        </Stack>
                    </div>
                </div>
            }

        </Modal>
    );
}

export default CustomizationModal;