import { Dropdown } from '@fluentui/react';
import React from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { useGetAllVendorSettingsQuery, useGetMetadataQuery } from '../../store/Api';
import { updateBestPriceExclusion } from '../../store/searchSlice';
import { renderVendorNameFromAllSettings } from '../../logic/Product';

const BestPriceExclusion: React.FC = (props) => {
    const value = useSelector((state: RootState) => state.search.bestPriceExclusion);
    const dispatch = useDispatch();
    const selectedCatalog = useSelector((state: RootState) => state.search.selectedCatalog);
    const metadata = useGetMetadataQuery(selectedCatalog?.name ?? '');
    const vendorSettings = useGetAllVendorSettingsQuery();
    const globalExclude = useSelector((state: RootState) => state.settings.selectedOrganization?.excludeFromBestPrice);
    return <Dropdown
        label='Exclude from Best Price'
        multiSelect
        options={metadata.data?.vendors?.map(v => ({key: v,
            text: renderVendorNameFromAllSettings(v, vendorSettings.data),
            disabled: globalExclude?.includes(v)})) ?? []}
        selectedKeys={value}
        onChange={(e,item) => {
            if (!item) return;
            if (value == undefined) {
                if (item.selected) dispatch(updateBestPriceExclusion([item.key as string]))
            } else {
                dispatch(updateBestPriceExclusion(
                    item.selected ? [...value, item.key as string] : value.filter(key => key !== item.key),
                ));
            }
        }}
    />;
}

export default BestPriceExclusion;