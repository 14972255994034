import React, {useEffect, useState} from "react";
import WorkspaceTable from "../components/Workspace/WorkspaceTable";
import WorkspaceControls from "../components/Workspace/WorkspaceControls";
import ExtensionModal from "../components/ExtensionModal/ExtensionModal";
import { Stack, Text } from "@fluentui/react";
import {IWorkspaceItem} from "../models/Workspace";
import WorkspaceTotalsDisplay from "../components/Workspace/WorkspaceTotalsDisplay";
import {IInstalledExtension, IRealTimeExtensionResponse} from "../models/Extension";
import {
    useGetInstalledExtensionsQuery,
    useGetRecentWorkspacesQuery,
    useGetWorkspaceProductsQuery,
    useGetWorkspaceQuery,
    useRunRealtimeExtensionMutation, useUpdateWorkspaceItemSortOrderMutation
} from "../store/Api";
import RecommendedItemsDisplay from "../components/Workspace/RecommendedItemsDisplay";
import DetailsModal from "../components/DetailsModal/DetailsModal";
import {NormalizeResult} from "../logic/Product";
import CAN, {EDIT_ITEM} from "../permissions/ability";
import WorkspaceImportModal from "../components/WorkspaceImportModal/WorkspaceImportModal";
import {RouteComponentProps} from "react-router-dom";
import CustomerRequestWindow from "../components/CustomerRequest/CustomerRequestWindow";
import AdHocItemModel from "../components/Workspace/AdHocItemModel";
import {useSelector} from "react-redux";
import {RootState} from "../store/rootReducer";
import { IsMutationSuccess } from "../logic/MutationTypeChecker";
import StorefrontBundleModal from "../components/Workspace/StorefrontBundleModal";
import Footer from "../components/Layout/Footer";

interface IWorkspacePageProps{
    workspaceId?: string;
}
const WorkspacePage: React.FC<RouteComponentProps<IWorkspacePageProps>> = (props) => {
    const [selectedWorkspace, setSelectedWorkspace] = useState<string>();
    const [selectedItems, setSelectedItems] = useState<IWorkspaceItem[]>([]);
    const [workspaceItems, setWorkspaceItems] = useState<IWorkspaceItem[]>([])
    const [selectedExtension, setSelectedExtension] = useState<IInstalledExtension | undefined>();
    const installedExtensions = useGetInstalledExtensionsQuery();
    const recentWorkspaces = useGetRecentWorkspacesQuery()
    const [detailsItem, setDetailsItem] = useState<IWorkspaceItem | undefined>();
    const canEditItem = CAN(EDIT_ITEM);
    const {data: workspaceData, isLoading: searchIsLoading} = useGetWorkspaceQuery(selectedWorkspace ?? "", 
        {skip: (!selectedWorkspace || selectedWorkspace == '')})
    const products = useGetWorkspaceProductsQuery(selectedWorkspace ?? "", 
        {skip: (!selectedWorkspace || selectedWorkspace == '')})
    const [workspaceName, setWorkspaceName] = useState<string>()
    const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
    const [selectedContact, setSelectedContact] = useState<string>();
    const [customerRequestModalOpen, setCustomerRequestModalOpen] = useState<boolean>(false);
    const [adHocItem, setAdHocItem] = useState<IWorkspaceItem | undefined>();
    const [adHocOpen, setAdHocOpen] = useState<boolean>(false);
    const [refreshingVendors, setRefreshingVendors] = useState<boolean>(false);
    const [realtimeErrors, setRealtimeErrors] = useState<{[key:string]: string}>({});
    const [showErrorCallout, setShowErrorCallout] = useState(false);
    const [storefrontBundleModalOpen, setStorefrontBundleModalOpen] = useState<boolean>(false);
    const [runRealtime] = useRunRealtimeExtensionMutation();

    useEffect(() => {
        if(props.match.params.workspaceId) {
            if(props.match.params.workspaceId === 'last' && recentWorkspaces.data && recentWorkspaces.data.length > 0){
                setSelectedWorkspace(recentWorkspaces.data[0].id)
            }
            else if(props.match.params.workspaceId && !(props.match.params.workspaceId === 'last')){
                setSelectedWorkspace(props.match.params.workspaceId)
            }
        }

    }, [props.match.params.workspaceId, recentWorkspaces]);

    useEffect(() => {
        const name = searchIsLoading ? "Loading Workspace" : workspaceData?.isTemplate 
            ? `${workspaceData?.name} Workspace (Template)` 
            : `${workspaceData?.name ?? "Loading"} Workspace`
        setWorkspaceName(name)
        if(workspaceData){
            setSelectedContact(workspaceData.contactId)
        }
    }, [searchIsLoading, workspaceData]);
    useEffect(() => {
        if(products.data) {
            setWorkspaceItems(products.data)
        }
    }, [products.data]);

    const refreshVendors = async () => {
        if (!selectedWorkspace) return;
        setRefreshingVendors(true);
        const promises = [];
        for (const ext of installedExtensions.data ?? []) {
            if (ext.extensionType !== 'realtime' || !ext.enabled) continue;
            promises.push(
                runRealtime({extension: ext, workspace: selectedWorkspace ?? ""})
                .then((result) => {
                    if (IsMutationSuccess<IRealTimeExtensionResponse>(result)) {
                        if (result.data?.success) {
                            setRealtimeErrors((e) => {
                                const newErrors = {...e};
                                delete newErrors[ext.nickname ?? ext.name];
                                return newErrors;
                            });
                            return;
                        }
                        setRealtimeErrors({...realtimeErrors, [ext.nickname ?? ext.name]: result.data?.error ?? 'Unknown Error'});
                        setShowErrorCallout(true);
                        return;
                    }
                    setRealtimeErrors({...realtimeErrors, [ext.nickname ?? ext.name]: 'API Call Failed'});
                    setShowErrorCallout(true);
                })
            );
        }
        await Promise.all(promises);
        setRefreshingVendors(false);
    }

    const getSelectedItems = () => {
        if(selectedItems && selectedItems.length > 0){
            return selectedItems
        }
        if(products.data){
            return workspaceItems
        }
        return []
    }

    const organization = useSelector((state: RootState) => state.settings.selectedOrganization);

    function onAdhocClick(item: IWorkspaceItem | undefined) {
        if (item) setAdHocItem(item);
        else setAdHocItem(undefined);
        setAdHocOpen(true);
    }

    return (
        <div className="main-content">
            <Stack horizontal>
                <Stack.Item grow align='center'>
                    <Text variant='xLargePlus'>{workspaceName}</Text>
                </Stack.Item>
                <Stack.Item align='end'>
                    {workspaceData?.id && <WorkspaceControls
                        selectedContact={selectedContact}
                        selectedWorkspace={workspaceData.id}
                        onRefresh={() => products.refetch()}
                        selectedItems={getSelectedItems()}
                        onExtensionSelection={(ext) => setSelectedExtension(ext)}
                        onImportSelection={() => setImportModalOpen(true)}
                        onCustomerRequestSelection={() => setCustomerRequestModalOpen(true)}
                        onAdhocClick={() => onAdhocClick(undefined)}
                        onStorefrontSelection={() => setStorefrontBundleModalOpen(true)}
                        refreshingVendors={refreshingVendors}
                        onRefreshVendors={refreshVendors}
                    />}
                </Stack.Item>
            </Stack>
            {workspaceData?.id && <WorkspaceTotalsDisplay 
                workspace={workspaceData.id}
            />}
            {(installedExtensions.data && workspaceData?.id) && installedExtensions.data.filter(f => 
                f.extensionType === 'recommendation').map((ext) => 
                <RecommendedItemsDisplay 
                    extension={ext} 
                    workspace={workspaceData.id} 
                    key={`${ext.id}-recommendedItems`}
                />)}
            {workspaceData?.id && 
                <WorkspaceTable
                    selectedItems={selectedItems}
                    onSelectedItemsChanged={(items) => setSelectedItems(items)}
                    workspace={workspaceData.id}
                    onDetailsClick={(i) => setDetailsItem(i)}
                    onAdhocClick={(i) => onAdhocClick((i))}
                />
            }
            {workspaceData?.id && selectedExtension && 
            <ExtensionModal
                workspaceId={workspaceData.id}
                extension={selectedExtension}
                isOpen={true}
                onClose={() => {
                    setSelectedExtension(undefined)
                    products.refetch()
                }}
            />}
            {(detailsItem?.product) &&
                <DetailsModal product={NormalizeResult(detailsItem.product)} open={true} onClose={() => setDetailsItem(undefined)} preventEdit={!canEditItem} />}
            {(detailsItem?.product) && 
                <DetailsModal 
                    product={NormalizeResult(detailsItem.product)} 
                    open={true} 
                    onClose={() => setDetailsItem(undefined)} 
                    preventEdit={!canEditItem} />}
            {adHocOpen &&
                <AdHocItemModel open={adHocOpen} onClose={() => setAdHocOpen(false)} workspace={workspaceData?.id ?? ""} product={adHocItem}/>}
            <WorkspaceImportModal
                isOpen={importModalOpen}
                onClose={() => {
                    setImportModalOpen(false)
                }}
                workspaceId={workspaceData?.id ?? ""}
                />
            {organization?.llmEnabled && <CustomerRequestWindow
                isOpen={customerRequestModalOpen}
                onClose={() => setCustomerRequestModalOpen(false)}
                workspaceId={selectedWorkspace}
            />}
            <StorefrontBundleModal
                isOpen={storefrontBundleModalOpen}
                onDismiss={() => setStorefrontBundleModalOpen(false)}
                workspace={workspaceData}
                />
            <Footer className={'powered-by-footer-absolute'}/>
        </div>
    );
}

export default WorkspacePage;
