import React, {useState} from "react";
import DataField from "../../components/Common/DataField";
import {MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize, TextField} from "@fluentui/react";
import {EmbeddedLoginDAL} from "../../dal/EmbeddedLoginDAL";
import {setEmbeddedAuth} from "../../store/authSlice";
import {setSessionRefreshCode} from "../../store/settingsSlice";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { setMappedExtensionFields, setRefreshSource, setTargetExtension } from "../../store/settingsSlice";
import {RootState} from "../../store/rootReducer";
import {b2cPolicies} from "../../authConfig";
import {useMsal} from "@azure/msal-react";

export const EmbeddedLogin: React.FC = () => {
    const [username, setUsername] = useState<string>()
    const [password, setPassword] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [loading, setLoading] = useState<boolean>()
    const [frameCheckLoading, setFrameCheckLoading] = useState<boolean>(true)
    const history = useHistory();
    const queryString = window.location.search;
    const dispatch = useDispatch()
    const query = new URLSearchParams(location.search);
    const targetExtension = query.get('source');
    const refreshParentOnExtensionSubmit = query.get('refreshSource')
    const targetExtensionFields = query.get('mappedFields')
    const externalUsername = query.get('username')
    const settings = useSelector((state: RootState) => state.settings);
    const EMBEDDED_LOGIN_CONTEXT = 'EmbeddedLogin'
    const { instance } = useMsal();
    const dal = new EmbeddedLoginDAL();
    dal.getFrameableHosts().then((a) => {
        const url = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;
        console.log('Checking for eligible location:', url)
        const formalUrl = new URL(url)
        let validUrl = false;
        a?.forEach(a => {
            if(formalUrl.hostname.includes(a)){
                validUrl = true;
            }
        })
        
        if(!validUrl){
            console.log('Ineligible URL. Sending to normal login page.')
            instance.loginRedirect().then(null)
        }
        setFrameCheckLoading(false)
    })
    React.useEffect(() => {
        console.log('Checking existing session...')
        if(settings.sessionRefreshCode && username){
            setLoading(true)
            const dal = new EmbeddedLoginDAL();
            dal.postValidateExistingSession({username: username, context:EMBEDDED_LOGIN_CONTEXT, sessionResolverToken: settings.sessionRefreshCode}).then((a) => {
                if(!a?.success){
                    console.log('Existing session expired. Login required.')
                    setLoading(false)
                    setEmbeddedAuth('')
                    return;
                }
                else if(a?.message){
                    console.log('Found existing session. Logging in.')
                    dispatch(setEmbeddedAuth(a.message))
                    if(queryString) {
                        history.push(`/${queryString}&sessionKey=${a.message}`)
                    }
                    else{
                        history.push(`/?sessionKey=${a.message}`)
                    }
                }
                setLoading(false)
            });
        }
    }, [settings.sessionRefreshCode, username])
    React.useEffect(() => {
        if (targetExtension && targetExtensionFields) {
            try {
                const mappedFields: { [key: string]: string; } = JSON.parse(targetExtensionFields)
                dispatch(setTargetExtension(targetExtension));
                dispatch(setMappedExtensionFields(mappedFields));
                setUsername(externalUsername ?? '');
            } catch {
                console.log('Could not map fields.')
            }
        }
    }, [targetExtension, targetExtensionFields]);

    React.useEffect(() => {
        if (refreshParentOnExtensionSubmit) {
            dispatch(setRefreshSource(true))
        }
    }, [refreshParentOnExtensionSubmit]);

    const submitLoginAttempt = () => {
        setLoading(true)
        setErrorMessage('')
        if(!username || !password) return;
        const dal = new EmbeddedLoginDAL();
        dal.postCreateEmbeddedSession({password: password, username: username, context: EMBEDDED_LOGIN_CONTEXT}).then((a) => {
            if(!a?.success){
                setErrorMessage(a?.message ?? "Unable to login with provided details. Please try again.")
                setLoading(false)
                return;
            }
            else if(a?.message){
                dispatch(setEmbeddedAuth(a.message))
                dispatch(setSessionRefreshCode(a.id))
                if(queryString) {
                    history.push(`/${queryString}&sessionKey=${a.message}`)
                }
                else{
                    history.push(`/?sessionKey=${a.message}`)
                }
            }
            setLoading(false)
        });
    }
    return(<div className={'embedded-login-container'}>

        <div className={'embedded-login'}>
            {window.config.overrideLogo != null ? 
                <img src={window.config.overrideLogo} width={250} alt={"White Label Logo"}/> :
                <img src={"mainLogo.png"} width={250} alt={"Adaptive Catalog Logo"}/>}
            {errorMessage &&
                <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>
            }
            {(loading || frameCheckLoading) ? <Spinner size={SpinnerSize.large}/> : 
            <div>
                <DataField
                    label={'Email Address'}
                    onChange={(event, value) => {
                        setUsername(value)
                    }}
                    value={username}
                />
                <TextField
                    label="Password"
                    type="password"
                    canRevealPassword
                    revealPasswordAriaLabel="Show password"
                    onChange={(event, value) => {
                        setPassword(value)
                    }}
                    value={password}
                    onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                            submitLoginAttempt()
                        }
                    }}
                />
                <br/>
                <PrimaryButton
                    text={'Sign In'}
                    disabled={!username || !password}
                    onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                            submitLoginAttempt()
                        }
                    }}
                    onClick={() => {
                        submitLoginAttempt()
                    }}
                />
            </div>}
        </div>
    </div>)
}

export default EmbeddedLogin